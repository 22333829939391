import { log, error, warn } from 'components/vendor/perform/core';
import { getDeviceInfo } from 'components/vendor/perform/utils';
import { getUserLocation, getSetting } from 'components/vendor/perform/localization';
import { currentBreakpoint } from 'components/vendor/perform/responsive';
import userAccount from 'assets/components/user-account/';

const KEY_EVENT = 'event';

/**
 * checks if variable name passed to GTM is proper
 * @function isProperVariableName
 * @param {string} name - Custom varaible name for sended data
 * @private
 */
function isProperVariableName(name) {
  const type = $.type(name);

  if (type !== 'string') {
    error(`GTM: custom variable name should be string, passed: ${type}`);
    return false;
  }

  if (name === '') {
    warn('GTM: custom variable name should not be empty string');
    return false;
  }

  return true;
}

/**
 * push data to GTM data layer
 * @function push
 * @param {string} name - name for sended data
 * @param {Object} [data={}] - Data sended to GTM data layer
 * @public
 */
export function push(name, data = {}) {
  return;
  if (!isProperVariableName(name)) {
    return;
  }

  if (!window.dataLayer) {
    error('GTM: script not loaded');
    return;
  }

  const prefixedData = {};
  const prefix = getSetting('variant.tracking-codes.google.gtm.prefix');

  Object.keys(data).forEach(key => {
    if (key !== KEY_EVENT) {
      prefixedData[`${prefix}.${key}`] = data[key];
    }
  });

  prefixedData.event = `${prefix}.${name || data.event}`;

  log('component/gtm: dataLayer push', prefixedData);
  window.dataLayer.push(prefixedData);

  window.testData = window.testData || {};
  window.dataLayer.forEach(dataPush => {
    window.testData.dataLayer = $.extend(window.testData.dataLayer || {}, dataPush);
  });
}

/**
 * Initializer
 * @function init
 */
export function init() {
  const deviceInfo = getDeviceInfo().device || {};

  push('screenSizeLoaded', {
    pageBreakpoint: currentBreakpoint,
    deviceType: deviceInfo.type || 'desktop',
  });

  $(window).on('breakpointchange', (ev) => {
    push('breakpointChanged', {
      pageBreakpoint: ev.current,
    });
  });

  // user country code
  getUserLocation().done((location) => {
    push('userCountryCodeLoaded', { userCountryCode: location });
  });

  push('loginStateLoaded', { loginState: userAccount.checkLoggedIn() });

  const loginStateChanged = 'loginStateChanged';

  userAccount.on('login', () => {
    push(loginStateChanged, { loginState: true });
  });

  userAccount.on('logout', () => {
    push(loginStateChanged, { loginState: false });
  });
}
