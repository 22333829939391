const SPORT_TYPE_FOOTBALL = 'football';
const SPORT_TYPE_BASKETBALL = 'basketball';

const sports = [
  SPORT_TYPE_FOOTBALL,
  SPORT_TYPE_BASKETBALL,
];

export {
  SPORT_TYPE_FOOTBALL as football,
  SPORT_TYPE_BASKETBALL as basketball,
  sports,
};
