import { local as localStorage } from 'components/vendor/perform/storage';
import 'widgets/gdpr-banner/style.scss';

/**
 * GPDR banner widget module
 * @param {Object} context - context object
 */
export default function (context) {
  const $context = $(context);
  const classWidget = 'widget-gdpr-banner';
  const selectorWidget = `.${classWidget}`;
  const classWidgetHidden = `${classWidget}--hidden`;
  const selectorAcceptBtn = `${selectorWidget}__accept`;
  const selectorRejectBtn = `${selectorWidget}__reject`;
  const storageKeyDecided = `${classWidget}.decided`;
  const $acceptBtn = $context.find(selectorAcceptBtn);
  const $rejectBtn = $context.find(selectorRejectBtn);

  /**
   * Toggle banner visibility based on storage data
   */
  function toggleBanner() {
    $context.toggleClass(classWidgetHidden, localStorage.get(storageKeyDecided));
  }

  $acceptBtn.on('click', () => {
    localStorage.set(storageKeyDecided, true);
    toggleBanner();
  });

  $rejectBtn.on('click', () => {
    localStorage.set(storageKeyDecided, true);
    toggleBanner();
  });

  toggleBanner();
}
