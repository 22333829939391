import { applyEventsMap } from 'components/ga';

const selectorSecondaryNavLinkCareers =
  '.widget-navigation-secondary__item[data-target="careers-dazn"] a';

const gaEventMapping = {
  [selectorSecondaryNavLinkCareers]: {
    category: 'FooterLinks',
    action: 'CareerClick',
    label: 'CareerLink',
  },
};

applyEventsMap(gaEventMapping);
