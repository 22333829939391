/**
 * Check if index is in range
 * @param {number} index - index to check
 * @param {number} start - range start
 * @param {number} end - range end
 * @returns {boolean} - true if index is in range
 */
export function isInRange(index, start, end) {
  return start <= index && end >= index;
}

/**
 * Normalize string replacing diacritics with latin equivalent
 * @param {string} string - string to normalize
 * @returns {string} - normalized string if normalize function is supported
 */
export function normalize(string) {
  return typeof string.normalize === 'function' ?
    string.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : string;
}

/**
 * Keywords marker class
 */
export default class KeywordsMarker {
  /**
   * Keywords marker constructor
   * @param {string} widgetClass - widget class
   */
  constructor(widgetClass) {
    this.normalizedKeywords = [];
    this.widgetClass = widgetClass;
  }

  /**
   * Set and normalize keywords
   * @param {Array} newKeywords - new keywords to set
   */
  set keywords(newKeywords) {
    this.normalizedKeywords = newKeywords.map(normalize);
  }

  /**
   * Mark keywords in result text
   * @param {string} resultText - result text to mark keywords
   * @returns {TemplateResult|string} - result text template with marked keywords
   */
  mark(resultText) {
    const indexes = [];
    const normalized = normalize(resultText);

    this.normalizedKeywords.forEach(keyword => {
      const regex = new RegExp(keyword, 'gi');
      let found;
      while ((found = regex.exec(normalized)) !== null) { // eslint-disable-line no-cond-assign
        const newIndex = [
          found.index,
          found.index + keyword.length,
        ];

        const expandsRange = indexes.some(([start, end], index) => {
          if (isInRange(newIndex[0], start, end) || isInRange(newIndex[1], start, end)) {
            indexes[index] = [
              Math.min(newIndex[0], start),
              Math.max(newIndex[1], end),
            ];

            return true;
          }

          return false;
        });

        if (!expandsRange) {
          indexes.push(newIndex);
        }
      }
    });

    if (!indexes.length) {
      return resultText;
    }

    indexes
      .sort(([start1], [start2]) => start2 - start1)
      .forEach(([start, end]) => {
        const before = resultText.substr(0, start);
        const inside = resultText.substring(start, end);
        const after = resultText.substr(end);

        resultText =
          `${before}<span class='${this.widgetClass}__result-mark'>${inside}</span>${after}`;
      });

    return resultText;
  }
}
