import { warn } from 'components/vendor/perform/core';
import 'widgets/nesine/most-played-coupons/style.scss';


import moment from 'moment';

const readyPromises = {};

/**
 * Get widget ready promise for mode
 * @param {string} mode - Widget mode
 * @returns {Object} - Mode promise
 */
function getPromise(mode) {
  if (!readyPromises[mode]) {
    readyPromises[mode] = {};
    readyPromises[mode].promise = new Promise((resolve) => {
      readyPromises[mode].resolve = resolve;
    });
  }

  return readyPromises[mode];
}

window.mksh = window.mksh || {};
window.mksh.mostPlayedCoupons = {
  onReady: (callback, mode = 'desktop') => {
    getPromise(mode).promise.then(callback);
  },
};

const fetchPromises = {};

/**
 * Fetch data
 * @param {string} feedUrl - feed url
 * @returns {Promise} - data fetch promise
 */
function fetchData(feedUrl) {
  if (fetchPromises[feedUrl]) {
    return fetchPromises[feedUrl];
  }

  fetchPromises[feedUrl] = fetch(feedUrl)
    .then(response =>
      new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(response);
        }
      })
    );

  return fetchPromises[feedUrl];
}

/**
 * Iddaa markets widget module
 * @param {Object} context - context object
 * @param {Object} settings - settings
 * @param {string} settings.feedUrl - Nesine popular bets feed url
 * @param {string} settings.mode - widget mode
 */
export default function (context, settings) {
  const feedUrl = settings.feedUrl;
  const mode = settings.mode;
  const imageUrls = settings.imageUrls;
  const widgetClass = 'widget-nesine-most-played-coupons';
  const widgetSelector = `.${widgetClass}`;
  const userProfilePicContainer = context.querySelector(`${widgetSelector}__user-profile-pic`);
  const userNameContainer = context.querySelector(`${widgetSelector}__user-name`);
  const playCountContainer = context.querySelector(`${widgetSelector}__play-count`);
  const linkContainer = context.querySelector(`${widgetSelector}__link`);
  const systemContainer = context
    .querySelector(`${widgetSelector}__total-bet-info-container-row-item\
.coupon-system-item`);
  const totalOddContainer = context
    .querySelector(`${widgetSelector}__total-bet-info-container-row-item-value\
.coupon-total-odd-value`);
  const totalOddContainer2 = context
    .querySelector(`${widgetSelector}__main-bet-info-container-value\
.coupon-total-odd-value`);
  const multiplyContainer = context
    .querySelector(`${widgetSelector}__total-bet-info-container-row-item-value\
.coupon-multiply-value`);
  const multiplyContainer2 = context
    .querySelector(`${widgetSelector}__main-bet-info-container-value\
.coupon-multiply-value`);
  const totalGainContainer = context
    .querySelector(`${widgetSelector}__total-bet-info-container-row-item-value\
.coupon-total-gain-value`);
  const totalGainContainer2 = context
    .querySelector(`${widgetSelector}__main-bet-info-container-value\
.coupon-total-gain-value`);

  const mainContainer = context.querySelector(`${widgetSelector}__main`);
  const matchList = $('ul.widget-nesine-most-played-coupons__match-list');
  const currencySuffix = 'TL';
  moment.locale('tr');
  fetchData(feedUrl)
    .then(response => {
      const {
        UserProfilePic: userImage,
        UserName: userName,
        PlayCount: playCount,
        TotalOdd: totalOdd,
        Multipy: multiply,
        TotalGain: totalGain,
        UserLink: userLink,
        System: system,
        Events: events,
      } = response[0];
      userProfilePicContainer.src = userImage;
      userProfilePicContainer.alt = userName;
      userNameContainer.innerText = userName;
      playCountContainer.innerText = playCount;
      totalOddContainer.innerText = totalOdd.toLocaleString('tr-TR', { minimumFractionDigits: 2 });
      totalOddContainer2.innerText = totalOdd.toLocaleString('tr-TR', { minimumFractionDigits: 2 });
      multiplyContainer.innerText = `${multiply.toLocaleString('tr-TR')} ${currencySuffix}`;
      multiplyContainer2.innerText = `${multiply.toLocaleString('tr-TR')} ${currencySuffix}`;
      totalGainContainer.innerText = `${totalGain.toLocaleString('tr-TR')} ${currencySuffix}`;
      totalGainContainer2.innerText = `${totalGain.toLocaleString('tr-TR')} ${currencySuffix}`;
      if (system) {
        const systemLabel = systemContainer.querySelector('.coupon-system-value');
        systemLabel.innerText = system;
      } else {
        systemContainer.style.display = 'none';
      }

      const events2 = [...events];
      // let events2 = [events[0]];
      if (settings.size === '320x480' && events2.length > 1) {
        mainContainer.style.position = 'static';
      }
      // eslint-disable-next-line prefer-arrow-callback
      $.each(events2, function (i) {
        const li = $('<li/>').addClass('widget-nesine-most-played-coupons__match')
          .appendTo(matchList);
        const matchName = $('<div/>')
          .addClass('widget-nesine-most-played-coupons__match-name')
          .appendTo(li);
        const sportLogo = $('<div/>')
          .addClass('widget-nesine-most-played-coupons__match-sport-logo')
          .appendTo(matchName);
        $('<img/>').attr('src', getIcon(events2[i].NSNMarketTypeId, imageUrls))
          .appendTo(sportLogo);
        if (events2[i].OutcomeModel[0].Banko) {
          const bankoLogo = $('<div/>').addClass('widget-nesine-most-played-coupons_' +
            '_match-banko-logo')
            .appendTo(matchName);
          $('<img/>').attr('src', getIcon('banko', imageUrls)).appendTo(bankoLogo);
        }
        const eventName = $('<div/>').addClass('widget-nesine-most-played-coupons' +
          '__match-event-name')
          .appendTo(matchName);
        $('<span/>').text(events2[i].EventName).appendTo(eventName);
        const matchTime = $('<div/>')
          .addClass('widget-nesine-most-played-coupons__match-time')
          .appendTo(li);
        Date(events2[i].StartDate.substring(6, events2[i].StartDate.length - 2));
        const matchDateString = moment
          .unix(events2[i].StartDate.substring(6, events2[i].StartDate.length - 5))
          .format('dddd HH:mm');
        $('<span/>').text(matchDateString).appendTo(matchTime);
        const betInfoContainer = $('<div/>')
          .addClass('widget-nesine-most-played-coupons__bet-info-container')
          .appendTo(li);
        const betInfoContainerMbs = $('<div/>')
          .addClass('widget-nesine-most-played-coupons__bet-info-container-mbs')
          // eslint-disable-next-line max-len
          .addClass(`widget-nesine-most-played-coupons__bet-info-container-mbs-${events2[i].OutcomeModel[0].Mbc}`)
          .appendTo(betInfoContainer);
        $('<span/>').text(events2[i].OutcomeModel[0].Mbc).appendTo(betInfoContainerMbs);
        const betInfoContainerType = $('<div/>')
          .addClass('widget-nesine-most-played-coupons__bet-info-container-type')
          .appendTo(betInfoContainer);
        $('<span/>').text(events2[i].NSNMarketName).appendTo(betInfoContainerType);
        const betInfoContainerBet = $('<div/>')
          .addClass('widget-nesine-most-played-coupons__bet-info-container-bet')
          .appendTo(betInfoContainer);
        $('<span/>').text(events2[i].OutcomeModel[0].Name).appendTo(betInfoContainerBet);
        const betInfoContainerOdd = $('<div/>')
          .addClass('widget-nesine-most-played-coupons__bet-info-container-odd')
          .appendTo(betInfoContainer);
        $('<span/>').text(events2[i].OutcomeModel[0].Odd.toFixed(2).toLocaleString('tr-TR'))
          .appendTo(betInfoContainerOdd);
      });
      const userLinkWithSuffix = `${userLink}&${settings.urlSuffix}`;
      const adLink = `${settings.urlPrefix}${encodeURIComponent(userLinkWithSuffix)}`;
      linkContainer.href = adLink;
      context.classList.remove(`${widgetClass}--hidden`);
      getPromise(mode).resolve();
    })
    .catch((error) => {
      warn('widget/nesine-most-played-coupons:', error);
      context.parentNode.removeChild(context);
    });

  function getIcon(marketTypeId) {
    if (marketTypeId === 'banko' && imageUrls) {
      return imageUrls.banko;
    }

    let marketType = 'hockey';
    if ((marketTypeId > 0 && marketTypeId < 110) ||
      (marketTypeId > 154 && marketTypeId < 166) ||
      (marketTypeId > 202 && marketTypeId < 226) ||
      (marketTypeId > 255 && marketTypeId < 258) ||
      marketTypeId === 259 ||
      (marketTypeId > 267 && marketTypeId < 324 && marketTypeId !== 312) ||
      (marketTypeId > 325 && marketTypeId < 331) ||
      (marketTypeId > 337 && marketTypeId < 344)) {
      marketType = 'futbol';
    } else if ((marketTypeId > 114 && marketTypeId < 153) ||
      (marketTypeId > 225 && marketTypeId < 232) ||
      (marketTypeId === 331) ||
      (marketTypeId === 336)) {
      marketType = 'basketbol';
    }

    if (imageUrls && imageUrls[marketType]) {
      return imageUrls[marketType];
    }

    return marketType;
  }
}
