import userAccount from 'assets/components/user-account';
import { getSetting } from 'components/vendor/perform/localization';
import { closest } from 'components/utils';
import pubsub from 'pubsub.js';
import 'widgets/login/style.scss';

/**
 * Login widget module
 * @param {Object} context - context object
 */
export default function (context) {
  const widgetClass = 'widget-login';
  const loggedInClass = `${widgetClass}--logged-in`;
  const menuClass = `${widgetClass}__menu`;
  const menuOpenedClass = `${menuClass}--opened`;
  const profileIconClass = `${widgetClass}__icon`;
  const lang = getSetting('localization.languageCode');
  const profileMenu = context.querySelector(`.${menuClass}`);
  const profileIcon = context.querySelector(`.${profileIconClass}`);
  const logoutLink = context.querySelector(`.${widgetClass}__link--logout`);

  const loginDialog = document.querySelector('#dialog-login');
  const loginDialogMainDiv = loginDialog.querySelector('.main');
  const successMessage = loginDialogMainDiv.querySelector('.success-message');
  const loginDiv = loginDialog.querySelector('.login');
  const loginMessage = loginDiv.querySelector('.message');
  const loginEmail = loginDialog.querySelector('#login-email');
  const loginPassword = loginDialog.querySelector('#login-password');
  const loginRemember = loginDialog.querySelector('#login-remember');
  const loginPasswordForgotten = loginDialog.querySelector('#login-password-forgotten');
  const btnLogin = loginDialog.querySelector('#btn-login');
  const btnCloseDialog = loginDialog.querySelector('#close-dialog');
  const btnSwitchToLoginLink = loginDialog.querySelector('#btn-switch-to-login');
  const btnFbLogin = loginDialog.querySelector('.fb');
  const btnGoogleLogin = loginDialog.querySelector('.g');
  const registerDiv = loginDialog.querySelector('.register');
  const registerMessage = registerDiv.querySelector('.message');
  const registerEmail = loginDialog.querySelector('#register-email');
  const registerFirstName = loginDialog.querySelector('#register-first-name');
  const registerLastName = loginDialog.querySelector('#register-last-name');
  const registerPassword = loginDialog.querySelector('#register-password');
  const registerPassword2 = loginDialog.querySelector('#register-password-2');
  const registerUsername = loginDialog.querySelector('#register-username');
  const registerGender = loginDialog.querySelector('#register-gender');
  const registerDobDay = loginDialog.querySelector('#register-dob-day');
  const registerDobMonth = loginDialog.querySelector('#register-dob-month');
  const registerDobYear = loginDialog.querySelector('#register-dob-year');
  const registerContractCheck = loginDialog.querySelector('#register-contract');
  const registerDataProtectionCheck = loginDialog.querySelector('#register-data-protection');
  const registerCommunicationClarificationCheck = loginDialog.querySelector('#register-communication-clarification');
  const registerConsentCheck = loginDialog.querySelector('#register-consent');
  const btnRegister = loginDialog.querySelector('#btn-register');
  const forgotPasswordDiv = loginDialog.querySelector('.forgot-password');
  const btnSwitchToLoginLink2 = loginDialog.querySelector('#btn-switch-to-login2');
  const btnResetPassword = loginDialog.querySelector('#btn-reset-password');
  const resetEmail = loginDialog.querySelector('#reset-email');

  const notLoggedInTitle = profileIcon.getAttribute('title');
  let isLoggedIn = false;

  /**
   * Prepare user initials
   * @param {string} username - user name
   * @returns {string} - Logged in user initials
   */
  function prepareInitials(username) {
    const initialsParts = username.split(/\s+/);

    if (initialsParts.length === 1) {
      return initialsParts[0].substr(0, 2);
    }

    return initialsParts[0].charAt(0) + initialsParts[1].charAt(0);
  }

  document.body.addEventListener('click', (event) => {
    if (!closest(event.target, `.${profileIconClass}`, context)) {
      profileMenu.classList.remove(menuOpenedClass);
    }
  }, false);

  window.parseJwt = () => {
    const parts = (`; ${document.cookie}`).split('; mk-token=');
    if (parts.length === 2) {
      const token = parts.pop().split(';').shift();
      if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64)
          .split('')
          .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

        return JSON.parse(jsonPayload);
      }
    }
    return null;
  };

  /**
   * Changes logged status depending on token cookie
   */
  function changeLoggedInStatus() {
    profileMenu.classList.remove(menuOpenedClass);
    const jwtData = window.parseJwt();
    if (jwtData !== null) {
      isLoggedIn = true;
      context.classList.add(loggedInClass);
      profileIcon.setAttribute('title', jwtData.given_name);
      profileIcon.innerHTML = prepareInitials(jwtData.given_name);
    } else {
      isLoggedIn = false;
      context.classList.remove(loggedInClass);
      profileIcon.innerHTML = '';
      profileIcon.style.backgroundImage = '';
      profileIcon.setAttribute('title', notLoggedInTitle);
    }
  }

  function closeLoginDialog() {
    $(loginDialog).hide();
    $([
      loginEmail,
      loginPassword,
      registerEmail,
      registerFirstName,
      registerLastName,
      registerPassword,
      registerPassword2,
      registerUsername,
      registerGender,
      registerDobDay,
      registerDobMonth,
      registerDobYear,
      resetEmail,
    ]).val('');
    // registerContractCheck = false;
    // registerDataProtectionCheck = false;
    // registerCommunicationClarificationCheck = false;
    // registerConsentCheck = false;
    registerMessage.innerHTML = '';
    registerMessage.style.display = 'none';
    loginMessage.innerHTML = '';
    loginMessage.style.display = 'none';
    successMessage.innerHTML = '';
    $(loginDialog).removeClass('success');
    $(loginDiv).show();
    $(registerDiv).hide();
    $(forgotPasswordDiv).hide();
    $(loginDialog).removeClass('loading');
  }

  function validateDate(year, month, day) {
    const d = new Date(year, month, day);
    if (d.getFullYear() === parseInt(year, 10) &&
      d.getMonth() === parseInt(month, 10) && d.getDate() === parseInt(day, 10)) {
      return new Date(Date.UTC(year, month, day)).toISOString();
    }

    return null;
  }

  function validateLoginForm() {
    loginMessage.innerHTML = '';
    loginMessage.style.display = 'none';
    let isValid = true;
    $([
      loginEmail,
      loginPassword,
    ]).removeClass('error').filter(function () {
      isValid = isValid && this.value.trim();
      return !this.value.trim();
    }).addClass('error');

    if (!isValid) {
      loginMessage.innerHTML = 'Lütfen gerekli alanları doldurunuz.';
      loginMessage.style.display = 'block';
      loginDialogMainDiv.scrollTop = 0;
    }

    return isValid;
  }

  function validateRegisterForm() {
    registerMessage.innerHTML = '';
    registerMessage.style.display = 'none';

    let isValid = true;
    $([
      registerEmail,
      registerFirstName,
      registerLastName,
      registerPassword,
      registerPassword2,
      registerUsername,
    ]).removeClass('error').filter(function () {
      isValid = isValid && this.value.trim();
      return !this.value.trim();
    }).addClass('error');

    if (!isValid) {
      registerMessage.innerHTML = 'Lütfen gerekli alanları doldurunuz.';
      registerMessage.style.display = 'block';
      loginDialogMainDiv.scrollTop = 0;
    } else if (/^[a-z0-9]*$/gi.exec(registerUsername.value) == null) {
      registerMessage.innerHTML =
        'Kullanıcı adı yalnızca harf ve rakam içerebilir. Lütfen kontrol ediniz.';
      registerMessage.style.display = 'block';
      loginDialogMainDiv.scrollTop = 0;
      $(registerUsername).addClass('error');
      return false;
    } else if ((registerDobDay.value || registerDobMonth.value || registerDobYear.value)
      && validateDate(registerDobYear.value,
        registerDobMonth.value - 1,
        registerDobDay.value) == null) {
        // Invalid date
      registerMessage.innerHTML = 'Lütfen doğum tarihiniz için geçerli bir tarih seçiniz.';
      registerMessage.style.display = 'block';
      loginDialogMainDiv.scrollTop = 0;
      return false;
    } else if (registerPassword.value !== registerPassword2.value) {
      registerMessage.innerHTML =
        'Onay şifreniz farklı. Lütfen şifrelerinizi kontrol ederek giriniz.';
      registerMessage.style.display = 'block';
      loginDialogMainDiv.scrollTop = 0;
      return false;
    } else if (registerPassword.value.length < 6) {
      registerMessage.innerHTML = 'Şifreniz en az 6 karakter olmalı.';
      registerMessage.style.display = 'block';
      loginDialogMainDiv.scrollTop = 0;
      return false;
    } else if (!registerContractCheck.checked || !registerDataProtectionCheck.checked || !registerCommunicationClarificationCheck.checked || !registerConsentCheck.checked) {
      registerMessage.innerHTML = 'Lütfen sözleşmeleri okuyup kabul ediniz.';
      registerMessage.style.display = 'block';
      loginDialogMainDiv.scrollTop = 0;
      return false;
    }

    return isValid;
  }

  // checking logged status on page load
  changeLoggedInStatus();

  profileIcon.addEventListener('click', () => {
    if (isLoggedIn) {
      profileMenu.classList.toggle(menuOpenedClass);
    } else {
      userAccount.login({ lang });
    }
  }, false);

  logoutLink.addEventListener('click', () => {
    if (isLoggedIn) {
      userAccount.logout({ lang });
      changeLoggedInStatus();
    }
  }, false);

  btnSwitchToLoginLink.addEventListener('click', () => {
    $(registerDiv).hide();
    $(forgotPasswordDiv).hide();
    $(loginDiv).fadeIn();
  }, false);

  btnSwitchToLoginLink2.addEventListener('click', () => {
    $(registerDiv).hide();
    $(forgotPasswordDiv).hide();
    $(loginDiv).fadeIn();
  }, false);

  loginPasswordForgotten.addEventListener('click', () => {
    $(registerDiv).hide();
    $(loginDiv).hide();
    $(forgotPasswordDiv).fadeIn();
  }, false);

  btnCloseDialog.addEventListener('click', () => {
    closeLoginDialog();
  }, false);

  btnFbLogin.addEventListener('click', () => {
    const win = window.open(`${window.authApiUrl}
/social/facebook-login?returnurl=${encodeURI(window.location.origin)}/auth`,
      '_blank',
      'scrollbars=yes, width=500, height=500, top=100, left=100');
    win.onbeforeunload = function () {
      closeLoginDialog();
      changeLoggedInStatus();
    };
  }, false);

  btnGoogleLogin.addEventListener('click', () => {
    const win = window.open(`${window.authApiUrl}
/social/google-login?returnurl=${encodeURI(window.location.origin)}/auth`,
      '_blank',
      'scrollbars=yes, width=500, height=500, top=100, left=100');
    win.onbeforeunload = () => {
      closeLoginDialog();
      changeLoggedInStatus();
    };
  }, false);

  $([loginEmail, loginPassword]).on('keypress', e => {
    if (e.which === 13) {
      $(btnLogin).trigger('click');
    }
  });

  $([resetEmail]).on('keypress', e => {
    if (e.which === 13) {
      $(btnResetPassword).trigger('click');
    }
  });

  btnRegister.addEventListener('click', () => {
    if (!validateRegisterForm()) {
      return;
    }

    $(loginDialog).addClass('loading');
    const data = {
      email: registerEmail.value,
      firstName: registerFirstName.value,
      lastName: registerLastName.value,
      username: registerUsername.value,
      password: registerPassword.value,
      gender: registerGender.value ? parseInt(registerGender.value, 10) : 0,
    };
    if (registerDobDay.value && registerDobMonth.value && registerDobYear.value) {
      data.dateOfBirth = validateDate(registerDobYear.value,
        registerDobMonth.value - 1,
        registerDobDay.value);
    }
    $.ajax({
      url: `${window.authApiUrl}/register`,
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
    }).done(response => {
      $(loginDialog).removeClass('loading');
      if (response.status === 0) {
        successMessage.innerHTML = `Eposta adresinizi doğrulayabilmeniz için size bir
 ileti gönderdik. Lütfen adresinizi doğrulayınız.`;
        $(loginDialog).addClass('success');
      } else if (response.status === -1) {
        registerMessage.innerHTML = `Girmiş olduğunuz email ve/veya kullanıcı adı zaten kayıtlı.
 Eğer şifrenizi hatırlamıyorsanız "Şifremi Unuttum" bağlantısını
 tıklayarak şifrenizi sıfırlayabilirsiniz.`;
        registerMessage.style.display = 'block';
        loginDialogMainDiv.scrollTop = 0;
      }
    });
  });

  btnLogin.addEventListener('click', () => {
    if (!validateLoginForm()) {
      return;
    }

    $(loginDialog).addClass('loading');

    $.ajax({
      url: `${window.authApiUrl}/login`,
      type: 'POST',
      data: JSON.stringify({ loginId: loginEmail.value, password: loginPassword.value }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
    }).done(response => {
      $(loginDialog).removeClass('loading');
      if (response.status === 0) {
        window.setCookie('mk-token', response.token, loginRemember.checked ? 365 : null);
        window.setCookie('mk-rtoken', response.refreshToken, loginRemember.checked ? 365 : null);
        window.setCookie('mk-remember-me', loginRemember.checked ? '1' : '0', 365);
        closeLoginDialog();
        changeLoggedInStatus();
      } else if (response.status === 1) {
        window.setCookie('mk-user-info', response.token);
        closeLoginDialog();
        window.open('/auth?status=1',
          '_blank', 'scrollbars=yes, width=500, height=500, top=100, left=100');
      } else if (response.status === 2) {
        loginMessage.innerHTML = `Hesabınız moderatörler tarafından onaylandıktan sonra
 aktif hale gelecektir. Hesabınız onaylandığı zaman size bir e-posta gönderilecektir.`;
        loginMessage.style.display = 'block';
        loginDialogMainDiv.scrollTop = 0;
      } else if (response.status === 3) {
        loginMessage.innerHTML = `Hesabınız geçici bir süreliğine askıya alınmıştır.
 Eğer bir yanlışlık olduğunu düşünüyorsanız lütfen bizimle iletişime geçiniz.`;
        loginMessage.style.display = 'block';
        loginDialogMainDiv.scrollTop = 0;
      } else if (response.status === 4) {
        loginMessage.innerHTML = `Bu hesap süresiz olarak bloklanmıştır.
 Eğer bir yanlışlık olduğunu düşünüyorsanız lütfen bizimle iletişime geçiniz.`;
        loginMessage.style.display = 'block';
        loginDialogMainDiv.scrollTop = 0;
      } else if (response.status === -1) {
        loginMessage.innerHTML = 'Kullanıcı adı veya şifre hatalı.';
        loginMessage.style.display = 'block';
        loginDialogMainDiv.scrollTop = 0;
      } else if (response.status === -2) {
        loginMessage.innerHTML = `Eposta adresinizi onaylamanız gerekmektedir.
 Eğer onay postası size ulaşmadıysa <span class="resend-verification"
style="text-decoration: underline;cursor: pointer;font-weight:bold;"
data-email="${encodeURIComponent(loginEmail.value)}">buraya tıklayınız.</span>`;
        loginMessage.style.display = 'block';
        loginDialogMainDiv.scrollTop = 0;
      }
    });
  }, false);

  btnResetPassword.addEventListener('click', () => {
    let isValid = true;
    $([
      resetEmail,
    ]).removeClass('error').filter(function () {
      isValid = isValid && this.value.trim();
      return !this.value.trim();
    }).addClass('error');
    if (!isValid) {
      return;
    }

    $(loginDialog).addClass('loading');
    $.ajax({
      url: `${window.authApiUrl}/reset-password`,
      type: 'POST',
      data: JSON.stringify({ email: resetEmail.value }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
    }).always(response => {
      if (response && response.status === 200) {
        $(loginDialog).removeClass('loading');
        successMessage.innerHTML =
          'Şifrenizi sıfırlamanız için eposta yoluyla bir bağlantı gönderdik.';
        $(loginDialog).addClass('success');
      }
    });
  }, false);

  $(loginDialog).on('click', '.resend-verification', function () {
    const $this = $(this);
    if (!$this.data('email')) {
      return;
    }

    $(loginDialog).addClass('loading');
    $.ajax({
      url: `${window.authApiUrl}/resend-verification`,
      type: 'POST',
      data: JSON.stringify({ email: decodeURIComponent($this.data('email')) }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
    }).always(response => {
      if (response && response.status === 200) {
        $(loginDialog).removeClass('loading');
        successMessage.innerHTML =
          'Eposta adresinizi onaylamanız için bir bağlantı gönderdik. Lütfen kontrol ediniz.';
        $(loginDialog).addClass('success');
      }
    });
  });

  pubsub.subscribe('header/hide', () => {
    if (isLoggedIn && profileMenu.classList.contains(menuOpenedClass)) {
      profileMenu.classList.remove(menuOpenedClass);
    }
  });
}
