import tabs from 'components/tabs';
import { pubsubNamespace } from 'components/vendor/perform/utils';
import pubsub from 'pubsub.js';
import { warn as logWarn } from 'components/vendor/perform/core';

import 'widgets/tabs/style.scss';

/**
 * Tabs widget module
 * @param {Object} context - context object
 * @param {Object} settings - widget settings
 */
export default function (context, settings) {
  const eventNamespace = pubsubNamespace(context);
  const $context = $(context);
  const selectorWidget = '.component-tabs';
  const selectorLink = `${selectorWidget}__link`;
  const selectorArrows = `${selectorWidget}__arrow`;
  const $arrows = $context.find(selectorArrows);
  const isUrlsMode = !!$context.find(selectorLink).filter('[href]').length;
  const tabsTable = {};
  const config = Object.assign(
    {
      forceDistributed: true,
    },
    settings
  );
  let activeTabId;
  let classContainerHidden;
  let arrowClick = false;

  tabs(context, {
    forceDistributed: config.forceDistributed,
  });

  /**
   * Hide/Show content for active tab
   */
  function onActiveTabChange() {
    if (activeTabId && arrowClick) {
      $context
        .find(`[data-tab="${activeTabId}"] ${selectorLink}`)
        .get(0)
        .click();
      arrowClick = false;
    }

    if (!Object.keys(tabsTable).length) {
      return;
    }

    Object.keys(tabsTable).forEach(tabId => {
      $(tabsTable[tabId].tabContentSelector).addClass(classContainerHidden);
    });

    if (!tabsTable[activeTabId]) {
      logWarn('No tabId has been plugged to tabs component');

      return;
    }

    $(tabsTable[activeTabId].tabContentSelector).removeClass(classContainerHidden);
  }

  /**
   * Init modules placed on the active tab
   */
  function initTabModules() {
    if (
      !tabsTable[activeTabId] ||
      !tabsTable[activeTabId].modulesContexts ||
      tabsTable[activeTabId].modulesInitialized
    ) {
      return;
    }

    tabsTable[activeTabId].modulesContexts.forEach(moduleContext => {
      pubsub.publish(`${pubsubNamespace(moduleContext)}/init`, [moduleContext]);
    });

    tabsTable[activeTabId].modulesInitialized = true;
  }

  pubsub.subscribe(`${eventNamespace}/set-class-container-hidden`,
    (tabsContext, newClass) => {
      if (tabsContext !== context) {
        return;
      }

      classContainerHidden = newClass;
    }
  );

  pubsub.subscribe(`${eventNamespace}/plug-tab-content`,
    (tabsContext, tabData) => {
      if (tabsContext !== context) {
        return;
      }

      tabsTable[tabData.tabId] = tabData;
      tabsTable[tabData.tabId].modulesInitialized = false;
    }
  );

  pubsub.subscribe(`${eventNamespace}/change`,
    (tabsContext, params) => {
      if (tabsContext !== context || !params.tabId) {
        return;
      }

      activeTabId = params.tabId;
      onActiveTabChange();
      initTabModules();
    }
  );

  $arrows.on('click', () => {
    if (!isUrlsMode) {
      return;
    }

    arrowClick = true;
  });

  onActiveTabChange();
}
