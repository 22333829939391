import pubsub from 'pubsub.js';
import { info } from 'components/vendor/perform/core';
import { breakpoint } from 'components/vendor/perform/responsive';

export const SLOT_MOBILE = 'mobile';
export const SLOT_DESKTOP = 'desktop';
export const SLOT_GPT = 'gpt';

export const BREAKPOINT_MOBILE = '740';

export const MESSAGE_TYPE = 'createMedyanetSlot';

const registeredSlots = {};
const slotsFromGoogleTag = {};
const replaceOnRegister = {};
const replacedSlots = {};
const slotParents = {};
const setHeightFor = {};

let slotType = getSlotType();

/**
 * Get slot type by checking current breakpoint
 * @returns {string} - detected slot type (mobile or desktop)
 */
function getSlotType() {
  return breakpoint('to', BREAKPOINT_MOBILE) ? SLOT_MOBILE : SLOT_DESKTOP;
}

/**
 * Check if Medyanet GPT API is ready
 * @returns {bool} - true if GPT API is ready
 */
function gptReady() {
  return true;
}

/**
 * Init Medyanet GPT ads loader
 */
export default function init() {
  window.addEventListener('message', ({ data }) => {
    const { type, sourceSlotId } = data;

    if (type !== MESSAGE_TYPE) {
      return;
    }

    info(`component/gpt: got message from ${sourceSlotId}, replacing to ${slotType}`);
    if (registeredSlots[sourceSlotId]) {
      replaceSlot(sourceSlotId, registeredSlots[sourceSlotId]);
    } else {
      replaceOnRegister[sourceSlotId] = true;
      info(`component/gpt: slot ${sourceSlotId} queued to replace`);
    }

    delete registeredSlots[sourceSlotId];
    delete slotsFromGoogleTag[sourceSlotId];
  }, false);

  pubsub.subscribe('responsive/breakpointchange', () => {
    const newSlotType = getSlotType();

    if (newSlotType !== slotType) {
      slotType = newSlotType;

      Object.keys(replacedSlots).forEach(slotId => {
        replaceSlot(slotId, replacedSlots[slotId]);
      });
    }

    if (gptReady()) {
      const slotIds = Object.keys(slotsFromGoogleTag);
      window.googletag = window.googletag || {};
      window.googletag.cmd = window.googletag.cmd || [];

      // eslint-disable-next-line prefer-arrow-callback
      window.googletag.cmd.push(function () {
        window.googletag.pubads().refresh(slotIds);
      });
      info(`component/gpt: refresh of slots: ${slotIds.join(', ')}`);
    }
  });

  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  gptCmd(() => {
    info('component/gpt: Google GPT Ready');
    var consent = {
      isFunctionalityChecked: true,
      isPerformanceChecked: true,
      isAdsChecked: true
    };
    try{
      var consentFromStorage = window.localStorage.getItem('cookieConsent');
      if (consentFromStorage) {
        consent = window.JSON.parse(consentFromStorage);
      }
    } catch(err) {
    }
    if (!consent.isAdsChecked) {
      googletag.pubads().setRequestNonPersonalizedAds(1);
    }

    window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
      const slotId = event.slot.getSlotId().getDomId();
      if (setHeightFor[slotId]) {
        info(`component/gpt: checking conditions for setting height for slot ${slotId}`);
      }

      if (
        !setHeightFor[slotId] || event.slot.getHtml().indexOf(MESSAGE_TYPE) !== -1 ||
        event.isEmpty || !event.slotContentChanged || event.size === 'fluid' ||
        (Array.isArray(event.size) && event.size[1] === 1)
      ) {
        if (setHeightFor[slotId]) {
          info(`component/gpt: setting height conditions not met for slot ${slotId}`, event);
        }
        return;
      }

      if (setHeightFor[slotId]) {
        const slotWrapper = document.getElementById(slotId).parentNode;
        const height = `${event.size[1]}px`;
        slotWrapper.style.height = height;
        info(`component/gpt: setting height ${height} for slot ${slotId}`);
      }
    });
  });
}

/**
 * Add command callback to Google GPT queue
 * @param {Function} callback - callback to add to gpt queue
 */
export function gptCmd(callback) {
  window.googletag.cmd.push(callback);
}

/**
 * Replace slot with new one
 * @param {string} oldSlotId - Id of slot to replace
 * @param {Object} newSlotSettings - new slot settings
 * @returns {bool} - true if slot was replaced
 */
export function replaceSlot(oldSlotId, newSlotSettings) {
  const oldSlot = document.getElementById(oldSlotId);
  if (oldSlot && oldSlot.parentNode && !slotParents[oldSlotId]) {
    slotParents[oldSlotId] = oldSlot.parentNode;
  }

  if (!slotParents[oldSlotId]) {
    info(`component/gpt: slot ${oldSlotId} not replaced, missing parent node`);
    return false;
  }

  const newSlot = createSlot(newSlotSettings);

  const setHeightForOldSlot = Boolean(setHeightFor[oldSlotId]);
  removeSlot(oldSlotId, !newSlot);

  if (!newSlot) {
    return false;
  }

  slotParents[oldSlotId].appendChild(newSlot);
  replacedSlots[newSlot.id] = newSlotSettings;

  if (setHeightForOldSlot) {
    setHeightFor[newSlot.id] = true;
  }

  registerSlotInGPT(newSlot.id, newSlotSettings[slotType]);
  info(`component/gpt: slot ${oldSlotId} replaced with ${newSlot.id}`);

  return true;
}

/**
 * Register slot in Medyanet GPT
 * @param {string} slotId - slot id to register
 * @param {object} settings - slot settings
 */
export function registerSlotInGPT(slotId, settings) {
  if (gptReady()) {
    info(`Register slot to gtp ${slotId}`);

    googleCmdPush(slotId, settings);
    info(`component/gpt: slot ${slotId} added to GPT`);
  }
}

/**
 * Build Medyanet slot
 * @param {Object} slotSettings - slot settings
 * @param {string|null} useSlotType - force slot type, set null to use current slot type
 * @returns {HTMLElement|null} - created GPT slot or null if slot has no settings
 */
export function createSlot(slotSettings, useSlotType = null) {
  useSlotType = useSlotType || slotType;

  const settings = slotSettings[useSlotType];

  if (!settings) {
    info(`component/gpt: new slot not created (no settings for ${useSlotType})`);
    return null;
  }

  const newSlot = document.createElement('div');
  newSlot.id = settings.slotId;

  [
    'size',
    'size-mapping',
    'oop',
    'platform',
    'lazy',
    'suffix',
    'targeting',
  ].forEach(attrName => {
    const settingName = attrName // change to camelCase
      .split('-')
      .map((part, index) => (index > 0 ? part.charAt(0).toUpperCase() + part.substr(1) : part))
      .join('');

    if (settings[settingName]) {
      let settingValue = settings[settingName];
      if (settingName === 'targeting' && !Array.isArray(settingValue)) {
        settingValue = JSON.stringify(
          Object.keys(settingValue).map((key) => [key, settingValue[key]])
        );
      }

      newSlot.setAttribute(`data-${attrName}`, settingValue);
    }
  });

  info(`component/gpt: new slot ${newSlot.id} created`);
  return newSlot;
}

/**
 * Find GPT slot
 * @param {string} slotId - slot id
 * @returns {Object|null} - GPT ad slot or null if not found
 */
function findGPTSlot(slotId) {
  if (!gptReady()) {
    return null;
  }
  if (slotId in slotsFromGoogleTag) {
    info(`component/gpt: found ${slotId} in GPT`);
    return slotsFromGoogleTag[slotId];
  }

  info(`component/gpt: ${slotId} not found in GPT`);
  return null;
}

/**
 * Destroy Medyanet slot in GPT
 * @param {string} slotId - slot id
 * @param {boolean} [removeOnlyFromGPT=false] - set true to remove slot only from GTP and keep
 * reference in replaced slots
 * @returns {bool} - true if slot was found and removed
 */
export function removeSlot(slotId, removeOnlyFromGPT = false) {
  const gptSlot = document.getElementById(slotId);

  if (!gptSlot) {
    info(`component/gpt: can't remove ${slotId}, slot not found`);
    return false;
  }

  if (gptSlot.parentNode && !slotParents[slotId]) {
    slotParents[slotId] = gptSlot.parentNode;
  }

  if (slotsFromGoogleTag) {
    const oldGptSlot = findGPTSlot(slotId);

    if (oldGptSlot) {
      const destroyResult = window.googletag.destroySlots([oldGptSlot]);
      info(`component/gpt: destroying slot ${slotId} in GPT, result: ${destroyResult}`);
    }

    if (slotId in slotsFromGoogleTag) {
      delete slotsFromGoogleTag[slotId];
    }
  }

  if (gptSlot.parentNode) {
    gptSlot.parentNode.removeChild(gptSlot);
  }

  if (!removeOnlyFromGPT) {
    delete replacedSlots[slotId];
    delete setHeightFor[slotId];
    info(`component/gpt: slot ${slotId} node removed from DOM`);
  }

  info(`component/gpt: slot ${slotId} removed (only from GPT: ${removeOnlyFromGPT})`);
  return true;
}

/**
 * Register Medyanet slot
 * @param {string} slotId - gpt slot id
 * @param {Object} settings - gpt slot widget settings
 * @param {string} settings.defaultGroup - default group which is used
 * @param {Object} settings.slotSettings - slot settings
 * @param {string} settings.setHeight - true if slot should have set height on ad load
 */
export function registerSlot(slotId, { defaultGroup, slotSettings, setHeight }) {
  registeredSlots[slotId] = slotSettings;
  const group = defaultGroup || slotType;
  registerSlotInGPT(slotId, slotSettings[group]);

  if (defaultGroup !== SLOT_GPT) {
    replacedSlots[slotId] = slotSettings;

    if (slotType !== defaultGroup) {
      replaceSlot(slotId, slotSettings);
    }
  }
  if (replaceOnRegister[slotId]) {
    delete replaceOnRegister[slotId];
    replaceSlot(slotId, slotSettings);
  }

  if (setHeight) {
    setHeightFor[slotId] = true;
  }

  info(`component/gpt: slot ${slotId} registered`);
}

/**
 * Unregister slot from GTP
 * @param {string} slotId - slot id to unregister
 * @param {boolean} [removeOnlyFromGPT=false] - set true to remove slot only from GTP and keep
 * reference in replaced slots
 */
export function unregisterSlot(slotId, removeOnlyFromGPT = false) {
  removeSlot(slotId, removeOnlyFromGPT);
  delete registeredSlots[slotId];
  delete replaceOnRegister[slotId];
  delete replacedSlots[slotId];
  delete setHeightFor[slotId];
  info(`component/gpt: slot ${slotId} unregistered (only from GPT: ${removeOnlyFromGPT})`);
}

export function googleCmdPush(id, settings) {
  window.googletag = window.googletag || { cmd: [] };
  const googletag = window.googletag;
  let push = 0;
  try {
    // eslint-disable-next-line prefer-arrow-callback
    googletag.cmd.push(function () {
      googletag.pubads().setCentering(true);
      googletag.pubads().disableInitialLoad();
      googletag.pubads().collapseEmptyDivs(true, true);
      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 100,
        renderMarginPercent: 20,
        mobileScaling: 2.0,
      });
      googletag.enableServices();
      const slotName = settings.slotName;
      const sizes1 = JSON.parse(`[${settings.size.replaceAll("'", '"')}]`);
      let slot1 = null;
      if (settings.oop) {
        slot1 = googletag
          .defineOutOfPageSlot(slotName, id);
        const targets = Object.keys(settings.targeting);
        for (let j = 0; j < targets.length; j++) {
          const t = targets[j];
          slot1 = slot1.setTargeting(t, settings.targeting[t]);
        }
        slot1 = slot1.addService(googletag.pubads());
      } else {
        slot1 = googletag
          .defineSlot(slotName, sizes1, id);
        const targets = Object.keys(settings.targeting);
        for (let j = 0; j < targets.length; j++) {
          const t = targets[j];
          slot1 = slot1.setTargeting(t, settings.targeting[t]);
        }
        slot1 = slot1.addService(googletag.pubads());
        if (settings.sizeMapping) {
          const sizes = settings.sizeMapping.split('|');
          let mapping = googletag.sizeMapping();
          for (let i = 0; i < sizes.length; i++) {
            const size = sizes[i];
            const sizeMap = size.split(':');
            const screenSize = JSON.parse(sizeMap[0]);
            const screenSizes = JSON.parse(sizeMap[1].replaceAll("'", '"'));
            mapping = mapping.addSize(screenSize, screenSizes);
          }
          mapping = mapping.build();
          slot1.defineSizeMapping(mapping);
        }
      }
      slotsFromGoogleTag[id] = slot1;

      googletag.pubads().refresh([slot1]);
      // eslint-disable-next-line prefer-arrow-callback
      googletag.pubads().addEventListener('slotRenderEnded', function (event) {
        if (event.slot.getSlotElementId() === id) {
          if (event.isEmpty === true) {
            if (push === 0) {
              push = 1;
              const slot = slotsFromGoogleTag[id];
              googletag.pubads().refresh([slot]);
            }
          }
        }
      });
    });
  } catch (e) {
    console.log(e);
  }
}

