const delegatedEvents = [];
let supportsPassive = null;

/**
 * Get list of delegated events
 * @param {HTMLElement} element - element to which events where delegated
 * @returns {{}} - List of delegated events
 */
export function getDelegatedEvents(element) {
  const eventDelegation = delegatedEvents.reduce((acc, item) => {
    if (item.element === element) {
      return item;
    }

    return acc;
  }, null);

  if (eventDelegation) {
    return eventDelegation.events;
  }

  delegatedEvents.push({
    element,
    events: {},
  });

  return delegatedEvents[delegatedEvents.length - 1].events;
}

/**
 * Find closest element matching selector
 * @param {HTMLElement} element - starting element
 * @param {string} selector - selector to match
 * @param {HTMLElement} [root=document.documentElement] - root element
 * @returns {HTMLElement|null} - matched element or null if not found
 */
export function closest(element, selector, root = document.documentElement) {
  do {
    if (element.matches && element.matches(selector)) {
      return element;
    }

    element = element.parentNode;
  } while (element && element !== root);

  return null;
}

/**
 * Call callback for each element in element list
 * @param {NodeList} elementList - list of elements
 * @param {Function} callback - callback to call for each element in list
 */
export function forEachElement(elementList, callback) {
  Array.prototype.forEach.call(elementList, callback);
}

/**
 * Delegate event listener for ancestor element
 * @param {HTMLElement} element - element to which delegate event
 * @param {string} eventName - event name
 * @param {string} selector - css selector which event target should match
 * @param {Function} callback - callback to call
 */
export function delegateEvent(element, eventName, selector, callback) {
  const elementDelegatedEvents = getDelegatedEvents(element);

  if (!elementDelegatedEvents[eventName]) {
    elementDelegatedEvents[eventName] = [];
  }

  const delegateCallback = (event) => {
    const closestElement = closest(event.target, selector, element);

    if (closestElement) {
      callback.call(closestElement, event);
    }
  };

  elementDelegatedEvents[eventName].push({
    callback,
    selector,
    delegateCallback,
  });

  element.addEventListener(eventName, delegateCallback, false);
}

/**
 * Undelegate event from element
 * @param {HTMLElement} element - element to which event was delegated
 * @param {string} eventName - event name
 * @param {Function} callback - event callback
 * @param {string|null} [selector=null] - css selector
 * @returns {boolean} - true if event was undelegated
 */
export function undelegateEvent(element, eventName, callback, selector = null) {
  const elementDelegatedEvents = getDelegatedEvents(element);

  if (!elementDelegatedEvents[eventName]) {
    return false;
  }

  const delegatedEvent = elementDelegatedEvents[eventName].reduce((acc, item) => {
    if (item.callback === callback && (!selector || item.selector === selector)) {
      return item;
    }

    return acc;
  }, null);

  if (!delegatedEvent) {
    return false;
  }

  element.removeEventListener(eventName, delegatedEvent.delegateCallback, false);

  elementDelegatedEvents[eventName] = elementDelegatedEvents[eventName]
    .filter(item => item.delegateCallback !== delegatedEvent.delegateCallback);

  return true;
}

/**
 * Query for a widget wrapper inside other element
 * @param {HTMLElement} element - element in which search for a widget
 * @param {string} widgetName - widget name to query for
 * @returns {HTMLElement|null} - widget wrapper or null if none found
 */
export function queryWidget(element, widgetName) {
  const selectors = [
    `[data-module="${widgetName}"]`,
    `[data-module^="${widgetName},"]`,
    `[data-module$=",${widgetName}"]`,
    `[data-module*=",${widgetName},"]`,
  ];

  return element.querySelector(selectors.join(','));
}

/**
 * Check if browser supports passive event listeners
 */
export function passiveSupported() {
  if (supportsPassive !== null) {
    return;
  }

  supportsPassive = false;
  try {
    const opts = Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true;
      },
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (e) {} // eslint-disable-line no-empty
}

/**
 * Get element offset top to body;
 * @param {HTMLElement} element - element to check
 * @returns {number} - total offset top
 */
export function offsetTop(element) {
  let totalTop = element.offsetTop;

  while (element.offsetParent) {
    element = element.offsetParent;
    totalTop += element.offsetTop;
  }

  return totalTop;
}
