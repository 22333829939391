import { warn } from 'components/vendor/perform/core';
import { debounce } from 'components/vendor/perform/utils';
import { html, render } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat.js';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { classMap } from 'lit-html/directives/class-map.js';
import { delegateEvent, closest } from 'components/utils';
import { show as showLoader, hide as hideLoader } from 'components/loader';
import { pushEvent } from 'components/ga';
import KeywordsMarker from 'widgets/search/keywordsMarker';

import 'widgets/search/style.scss';
import 'widgets/search/form/style.scss';
import 'views/partials/widget-base/style.scss';

require('promise-polyfill/src/polyfill');
require('whatwg-fetch');

const MIN_KEYWORDS_LENGTH = 3;
const GA_EVENT_CATEGORY = 'Search';
const GA_EVENT_ACTION_RESULT = 'SearchboxResult';
const GA_EVENT_ACTION_TYPE = 'SearchboxType';
const GA_EVENT_ACTION_RESULT_PAGE = 'SearchResultPage';
const GA_EVENT_LABEL_RESULT = 'Searchbox Result';
const GA_EVENT_LABEL_TYPE = 'Searchbox Type';
const GA_EVENT_LABEL_RESULT_PAGE = 'Search Result Page';

/**
 * Search widget class
 */
class SearchWidget {
  /**
   * Search Widget constructor
   * @param {HTMLElement} context - widget context
   * @param {Object} settings - widget settings
   */
  constructor(context, settings) {
    this.context = context;
    this.settings = settings;
    this.widgetClass = 'widget-search';
    this.focusClass = `${this.widgetClass}--focus`;
    this.input = this.context.querySelector(`.${this.widgetClass}__input`);
    this.clearButton = this.context.querySelector(`.${this.widgetClass}__clear`);
    this.openCloseButton = this.context.querySelector(`.${this.widgetClass}__open-close`);
    this.list = this.context.querySelector(`.${this.widgetClass}__results`);
    this.callInProgress = 1;
    this.selectedIndex = -1;
    this.resultsCount = 0;
    this.controller = null;
    this.signal = null;
    this.currentResults = {};
    this.keywordsMarker = new KeywordsMarker(this.widgetClass);

    [
      'onInputChange',
      'onResultClick',
      'onKeyPress',
      'onClickOutside',
      'onFocus',
      'onClearClick',
      'onOpenCloseClick',
    ].forEach(callbackName => {
      this[callbackName] = this[callbackName].bind(this);
    });

    delegateEvent(this.context, 'click', `.${this.widgetClass}__result`, this.onResultClick);
    this.clearButton.addEventListener('click', this.onClearClick, false);
    this.input.addEventListener('input', debounce(this.onInputChange, 500), false);
    this.input.addEventListener('keydown', this.onKeyPress, false);
    this.input.addEventListener('focus', this.onFocus, false);
    this.openCloseButton.addEventListener('click', this.onOpenCloseClick, false);
    document.body.addEventListener('click', this.onClickOutside, false);
  }

  /**
   * Get keywords
   * @returns {string} - keywords
   */
  get keywords() {
    return this.input.value.trim();
  }

  /**
   * Check if form has focus
   * @returns {boolean} - true if form has focus
   */
  get hasFocus() {
    return this.context.classList.contains(this.focusClass);
  }

  /**
   * Set focus class
   * @param {boolean} value - focus flag
   */
  set hasFocus(value) {
    if (value) {
      this.context.classList.add(this.focusClass);
    } else {
      this.context.classList.remove(this.focusClass);
    }
  }

  /**
   * On open/clear button click
   */
  onClearClick() {
    if (this.hasFocus) {
      if (this.input.value !== '') {
        this.input.value = '';
        this.focus();
      } else {
        this.blur();
      }
    }
  }

  /**
   * On close button click
   */
  onOpenCloseClick() {
    if (this.hasFocus) {
      this.blur();
    } else {
      this.focus();
    }
  }

  /**
   * Check if keywords have minimum required length
   * @returns {boolean} - true if keywords are valid
   */
  areKeywordsValid() {
    return this.keywords.length >= MIN_KEYWORDS_LENGTH;
  }

  /**
   * Focus event handler
   */
  onFocus() {
    pushEvent(GA_EVENT_CATEGORY, GA_EVENT_ACTION_TYPE, GA_EVENT_LABEL_TYPE);
    this.focus();
  }

  /**
   * Focus form
   */
  focus() {
    this.onInputChange();
    this.hasFocus = true;
    this.input.focus();
    this.input.selectionStart = this.input.value.length;
    this.input.selectionEnd = this.input.value.length;
  }

  /**
   * Blur form
   */
  blur() {
    this.clearResults();
    this.hasFocus = false;
    this.input.blur();
  }

  /**
   * On search input event listener
   */
  onInputChange() {
    if (this.areKeywordsValid()) {
      showLoader(this.list);
      this.fetchResults(this.keywords).then(({ results }) => {
        this.currentResults = results;
        this.selectedIndex = -1;
        this.resultsCount = Object.keys(results).reduce((count, category) =>
          count + results[category].length, 0);
        this.keywordsMarker.keywords = this.keywords.split(/\s+/);
        this.renderResults();
      }).catch(message => {
        warn(`widget/search: ${message}`);
      });
    } else {
      this.clearResults();
    }
  }

  /**
  * just change static files hash
  */
  onDummyHappended() {
    console.log(11);
  }

  /**
   * Handle key press on search input
   * @param {KeyboardEvent} event - key press event
   */
  onKeyPress(event) {
    let newIndex = this.selectedIndex;
    switch (event.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        newIndex = Math.min(newIndex + 1, this.resultsCount - 1);
        break;
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        newIndex = Math.max(newIndex - 1, -1);
        break;
      case 'Enter':
        if (this.selectedIndex !== -1 || !this.areKeywordsValid() || this.resultsCount === 1) {
          event.stopPropagation();
          event.preventDefault();
        }

        if (this.resultsCount === 1) {
          this.gotoResult(0);
          pushEvent(GA_EVENT_CATEGORY, GA_EVENT_ACTION_RESULT, GA_EVENT_LABEL_RESULT);
        } else if (this.selectedIndex !== -1) {
          this.gotoResult(this.selectedIndex);
          pushEvent(GA_EVENT_CATEGORY, GA_EVENT_ACTION_RESULT, GA_EVENT_LABEL_RESULT);
        } else {
          pushEvent(GA_EVENT_CATEGORY, GA_EVENT_ACTION_RESULT_PAGE, GA_EVENT_LABEL_RESULT_PAGE);
        }
        return;
      case 'Esc': // IE/Edge specific value
      case 'Escape':
        this.clearResults();
        return;
      case 'Tab':
        this.blur();
        return;
      default:
        return;
    }

    if (newIndex >= 0 && newIndex !== this.selectedIndex) {
      event.preventDefault();
    }

    this.selectedIndex = newIndex;
    this.renderResults();
    this.scrollToSelectedIndex();
  }

  /**
   * Scroll results selected result
   */
  scrollToSelectedIndex() {
    if (this.selectedIndex === -1) {
      return;
    }

    const item = this.list.querySelector(`[data-index='${this.selectedIndex}']`);
    item.scrollIntoView(false);
  }

  /**
   * Outside click handler
   * @param {MouseEvent} event - click event
   */
  onClickOutside(event) {
    if (!closest(event.target, `.${this.widgetClass}`) && !this.input.matches(':focus')) {
      this.blur();
    }
  }

  /**
   * Clear results
   */
  clearResults() {
    this.cancelFetch();
    this.list.classList.remove(`${this.widgetClass}__list--loading`);
    this.currentResults = {};
    this.selectedIndex = -1;
    this.resultsCount = 0;
    hideLoader(this.list);
    render('', this.list);
  }

  /**
   * Result click handler
   * @param {Event} event - click event
   */
  onResultClick(event) {
    const item = closest(event.target, `.${this.widgetClass}__result`, this.context);

    if (!item) {
      return;
    }

    pushEvent(GA_EVENT_CATEGORY, GA_EVENT_ACTION_RESULT, 'Searchbox Result');

    this.gotoResult(item.dataset.index);
  }

  /**
   * Go to url for result with passed index
   * @param {number} index - result index to go to
   */
  gotoResult(index) {
    const item = this.list.querySelector(`[data-index='${index}']`);
    if (!item) {
      return;
    }

    window.location.href = item.dataset.url;
    this.clearResults();
  }

  /**
   * Cancel active fetch
   */
  cancelFetch() {
    if (this.controller) {
      this.controller.abort();
    }

    if (typeof AbortController !== 'undefined') {
      this.controller = new AbortController(); // eslint-disable-line no-undef
      this.signal = this.controller.signal;
    }

    this.callInProgress++;
  }

  /**
   * Fetch search results for given keywords
   * @param {string} keywords - keywords to fetch results for
   * @returns {Promise<Object>} - Search results fetch promise
   */
  fetchResults(keywords) {
    this.cancelFetch();

    this.callInProgress++;
    const currentCallId = this.callInProgress;

    const url = this.settings.url.replace('KEYWORDS', encodeURIComponent(keywords));

    return new Promise((resolve, reject) => {
      fetch(url, { signal: this.signal })
        .then(response => response.json())
        .then(response => {
          if (this.callInProgress !== currentCallId) {
            reject('New call in progress');
            return;
          } else if (response.status !== 'success') {
            reject(`Wrong response status code: ${response.status}`);
          }
          resolve(response.data);
        })
        .catch((...args) => {
          warn('widget/search: Error while fetching search results', ...args);
        })
        .finally(() => {
          if (this.controller) {
            this.controller = null;
          }
        });
    });
  }

  /**
   * Render search results
   */
  renderResults() {
    const groups = [];
    let startIndex = 0;

    if (!this.hasFocus) {
      return;
    }

    Object.keys(this.currentResults).forEach(category => {
      if (this.currentResults[category].length) {
        groups.push({
          id: category,
          template: html`${this.renderGroup(category, this.currentResults[category], startIndex)}`,
        });
        startIndex += this.currentResults[category].length;
      }
    });

    hideLoader(this.list);
    if (groups.length) {
      render(
        html`${repeat(groups, group => `search-result-${group.id}`, group => group.template)}`,
        this.list
      );
    } else {
      render(html`
        <li class="${this.widgetClass}__no-results">
            ${this.settings.translations.noResults}
        </li>
      `, this.list);
    }
  }

  /**
   * Render results group
   * @param {string} category - group category
   * @param {Array} results - group results
   * @param {number} startIndex - starting index of results
   * @returns {TemplateResult} - group template result
   */
  renderGroup(category, results, startIndex) {
    return html`
      <li class="${this.widgetClass}__group"  id="search-results-${category}">
        <h4 class="${this.widgetClass}__group-header">${this.settings.translations[category]}</h4>
        <ul class="${this.widgetClass}__group-list">
            ${repeat(
              results,
              result => `search-result-${result.id}`,
              result => this.renderCategory(category, result, startIndex++)
            )}
        </ul>
      </li>
    `;
  }

  /**
   * Render search result
   * @param {string} category - search result category
   * @param {Object} result - search result
   * @param {number} index - index of result
   * @returns {string|TemplateResult} - result template or empty string
   */
  renderCategory(category, result, index) {
    switch (category) {
      case 'contestants':
        return this.renderTeam(result, index);
      case 'players':
        return this.renderPlayer(result, index);
      case 'competitions':
        return this.renderCompetition(result, index);
      case 'matches':
        return this.renderMatch(result, index);
      default:
        return '';
    }
  }

  /**
   * Get search result classes
   * @param {string} modifier - result modifier
   * @param {number} index - result index
   * @returns {Object} - classes map for result
   */
  getResultClasses(modifier, index) {
    return {
      [`${this.widgetClass}__result`]: true,
      [`${this.widgetClass}__result--${modifier}`]: true,
      [`${this.widgetClass}__result--selected`]: index === this.selectedIndex,
    };
  }

  /**
   * Default result renderer
   * @param {Object} result - search result
   * @param {number} index - result index
   * @param {string} modifier - result modifier
   * @param {string} img - image src
   * @returns {TemplateResult} - default result template
   */
  renderDefault(result, index, modifier, img) {
    return html`
      <li class="${classMap(this.getResultClasses(modifier, index))}" data-url="${result.url}"
        id="search-result-${result.id}" data-index="${index}"
      >
        <img class="${this.widgetClass}__result-image
          ${this.widgetClass}__result-image--${modifier}"
          src="${img}" alt="${result.name || result.displayName}"
        />
        <span class="${this.widgetClass}__result-text
          ${this.widgetClass}__result-text--${modifier}"
        >
          <span>
            ${unsafeHTML(this.keywordsMarker.mark(result.name || result.displayName))}
          </span>
        </span>
      </li>
    `;
  }

  /**
   * Render team search result
   * @param {Object} result - team search result
   * @param {number} index - result index
   * @returns {TemplateResult} - team result template
   */
  renderTeam(result, index) {
    const img = this.settings.teamCrestUrl[result.sport].replace('TEAM_ID', result.id);
    return this.renderDefault(result, index, 'team', img);
  }

  /**
   * Render competition search result
   * @param {Object} result - competition search result
   * @param {number} index - result index
   * @returns {TemplateResult} - competition result template
   */
  renderCompetition(result, index) {
    const img = this.settings.flagUrl.replace('COUNTRY_ID', result.countryId);
    return this.renderDefault(result, index, 'competition', img);
  }

  /**
   * Render player search result
   * @param {Object} result - player search result
   * @param {number} index - result index
   * @returns {TemplateResult} - player result template
   */
  renderPlayer(result, index) {
    const img = this.settings.mugShotUrl[result.sport].replace('PLAYER_ID', result.id);
    return this.renderDefault(result, index, 'player', img);
  }

  /**
   * Get contestant by position from match
   * @param {Object} match - match search result
   * @param {string} position - position of a team (A - Home team, B - Away team)
   * @returns {Object|null} - team or null if no team found
   */
  getContestantByPosition(match, position) {
    return match.contestants.reduce((contestantForPosition, contestant) => {
      if (contestant.position === position) {
        return contestant;
      }

      return contestantForPosition;
    }, null);
  }

  /**
   * Render match search result
   * @param {Object} result - match search result
   * @param {number} index - result index
   * @returns {TemplateResult} - match result template
   */
  renderMatch(result, index) {
    const homeContestant = this.getContestantByPosition(result, 'A');
    const homeCrest = homeContestant ?
      this.settings.teamCrestUrl[result.sport].replace('TEAM_ID', homeContestant.id) : 'noPhoto';

    const awayContestant = this.getContestantByPosition(result, 'B');
    const awayCrest = awayContestant ?
      this.settings.teamCrestUrl[result.sport].replace('TEAM_ID', awayContestant.id) : 'noPhoto';

    return html`
        <li class="${classMap(this.getResultClasses('match', index))}"
          data-url="${result.url}" id="search-result-${result.id}" data-index="${index}"
        >
          <span class="${this.widgetClass}__result-team ${this.widgetClass}__result-team--home">
            <img class="${this.widgetClass}__result-image" src="${homeCrest}"
              alt="${homeContestant.displayName ? homeContestant.displayName : ''}"
            />
            <span class="${this.widgetClass}__result-text">
              ${
                homeContestant.displayName ?
                  homeContestant.displayName : this.settings.translations.noTeam
              }
            </span>
          </span>
          <span class="${this.widgetClass}__vs">
            ${this.settings.translations.teamsSeparator}
          </span>
          <span class="${this.widgetClass}__result-team ${this.widgetClass}__result-team--away">
            <img class="${this.widgetClass}__result-image" src="${awayCrest}"
              alt="${awayContestant.displayName ? awayContestant.displayName : ''}"
            />
            <span class="${this.widgetClass}__result-text">
              ${
                awayContestant.displayName ?
                  awayContestant.displayName : this.settings.translations.noTeam
              }
            </span>
          </span>
        </li>
    `;
  }
}

/**
 * Search widget
 * @param {HTMLElement} context - widget context
 * @param {Object} settings - widget settings
 */
export default function (context, settings) {
  return new SearchWidget(context, settings);
}
