import { isRTL, merge } from 'components/vendor/perform/utils';
import { getSetting } from 'components/vendor/perform/localization';
import { sports, football } from 'components/opta-widget/sports';

const scriptUrl = '';
const styleUrls = {};
const stylePromises = {};
const rtlStyleUrl = '';
const loadedWidgets = [];
const loadWidgetsCallbacks = {};
let scriptPromise;

const defaultParams = {
  subscription_id: getSetting('common.opta.widget.subscriptionId'),
  language: getSetting('localization.optaWidgetLanguageCode'),
  timezone: 'user',
  load_when_visible: false,
};

/**
 * loads style by url
 * @private
 * @param {string} url - style url to load
 * @returns {Promise} - style load promise
 */
function loadStyle(url) {
  if (stylePromises[url]) {
    return stylePromises[url];
  }

  /* eslint-disable new-cap */
  const loadDeferred = $.Deferred();
  /* eslint-enable new-cap */
  stylePromises[url] = loadDeferred.promise();

  const styleLink = document.createElement('link');
  styleLink.onload = () => {
    loadDeferred.resolve();
  };
  styleLink.rel = 'stylesheet';
  styleLink.href = url;
  document.getElementsByTagName('head')[0].appendChild(styleLink);

  return stylePromises[url];
}

/**
 * loads opta style
 * @function loadStyle
 * @param {string} [sport='football'] - sport type (one of 'football' or 'basketball')
 * @returns {Promise} - style load promise
 */
function loadOptaStyle(sport = football) {
  /* eslint-disable new-cap */
  const deferred = $.Deferred();
  /* eslint-enable new-cap */

  return deferred.promise();
}

/**
 * loads opta script with settings
 * @function loadScript
 * @param {Object} params - parameters
 * @returns {Promise} - script load promise
 */
function loadOptaScript(params) {
  if (scriptPromise) {
    return scriptPromise;
  }

  /* eslint-disable new-cap */
  const scriptDeferred = $.Deferred();
  /* eslint-enable new-cap */
  scriptPromise = scriptDeferred.promise();

  params = merge(defaultParams, params || {});

  // set opta settings for widgets
  window.opta_settings = params;

  const scriptLink = document.createElement('script');
  scriptLink.type = 'text/javascript';
  scriptLink.async = true;
  scriptLink.src = scriptUrl;
  scriptLink.onload = () => {
    if (typeof params.initCallback === 'function') {
      params.initCallback();
    }

    window.Opta.events.subscribe('widget.drawn', (widget) => {
      if (widget.widget.attr.name) {
        if (loadedWidgets.indexOf(widget.widget.attr.name) === -1) {
          loadedWidgets.push(widget.widget.attr.name);
        }
        runCallbacks();
      }
    });
    window.Opta.events.subscribe('application.init', () => {
      scriptDeferred.resolve();
    });
  };
  document.getElementsByTagName('head')[0].appendChild(scriptLink);

  return scriptPromise;
}

/**
 * resolve promises for loaded widgets
 * @function runCallbacks
 * @private
 */
function runCallbacks() {
  loadedWidgets
    .filter((name) => Boolean(loadWidgetsCallbacks[name]))
    .forEach((name) => loadWidgetsCallbacks[name]
      .forEach((callback) => callback())
    );
}

/**
 * adds listener that resolves when widget with name is loaded
 *
 * @function whenLoaded
 * @param {string} name - widget name / init callback
 * @param {optaCallback} callback - callback
 */
function whenLoaded(name, callback) {
  if (loadedWidgets.indexOf(name) !== -1) {
    callback();
  } else {
    if (!loadWidgetsCallbacks[name]) {
      loadWidgetsCallbacks[name] = [];
    }
    loadWidgetsCallbacks[name].push(callback);
  }
}

export {
  loadOptaStyle as loadStyle,
  loadOptaScript as loadScript,
  whenLoaded,
};
