import 'widgets/header/style.scss';
import { closest } from 'components/utils';
import { onRAF, pubsubNamespace } from 'components/vendor/perform/utils';
import pubsub from 'pubsub.js';

require('widgets/login');
require('widgets/legacy-link-banner');

const MIN_DIFFERENCE = 200;

/**
 * Header widget
 * @param {HTMLElement} context - widget context
 */
export default function (context) {
  const widgetClass = 'widget-header';
  const hiddenClass = `${widgetClass}--hidden`;
  const menuToggleClass = `${widgetClass}__menu`;
  const menuToggle = context.querySelector(`.${menuToggleClass}`);
  const navClass = `${widgetClass}__nav`;
  const headerOpenClass = 'header-opened';
  const eventNamespace = pubsubNamespace(context);
  let resetTimeout = null;
  let startY = window.scrollY;

  /**
   * Close menu
   */
  function closeMenu() {
    context.classList.remove(`${widgetClass}--open`);
  }

  /**
   * Open menu
   */
  function openMenu() {
    context.classList.add(`${widgetClass}--open`);
  }

  /**
   * Toggle Menu
   */
  function toggleMenu() {
    if (context.classList.contains(`${widgetClass}--open`)) {
      closeMenu();
    } else {
      openMenu();
    }
  }

  menuToggle.addEventListener('click', toggleMenu, false);

  document.body.addEventListener('click', (event) => {
    if (!closest(event.target, `.${menuToggleClass}, .${navClass}`, context)) {
      closeMenu();
    }
  }, false);

  /**
   * Set reset timeout
   */
  function setResetTimeout() {
    if (resetTimeout) {
      clearTimeout(resetTimeout);
    }

    resetTimeout = setTimeout(() => {
      startY = window.scrollY;
    }, 1000);
  }

  /**
   * Show header
   */
  function showHeader() {
    onRAF(() => {
      context.classList.remove(hiddenClass);
      document.body.classList.add(headerOpenClass);
      pubsub.publish(`${eventNamespace}/show`);
    });
  }

  /**
   * Hide header
   */
  function hideHeader() {
    onRAF(() => {
      context.classList.add(hiddenClass);
      document.body.classList.remove(headerOpenClass);
      pubsub.publish(`${eventNamespace}/hide`);
    });
  }

  window.addEventListener('scroll', () => {
    if (context.classList.contains(`${widgetClass}--open`)) {
      return;
    }

    const difference = startY - window.scrollY;
    setResetTimeout();

    if (Math.abs(difference) >= MIN_DIFFERENCE) {
      startY = window.scrollY;

      if (difference > 0 && context.classList.contains(hiddenClass)) {
        showHeader();
      } else if (difference < 0 && !context.classList.contains(hiddenClass)) {
        hideHeader();
      }
    } else if (window.scrollY === 0 && context.classList.contains(hiddenClass)) {
      showHeader();
    }
  }, {
    passive: true,
  });

  showHeader();
}
