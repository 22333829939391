/**
 * Lazy load module
 * @namespace vendor/perform/components/lazy-load
 */

import { onRAF, is, throttle } from 'components/vendor/perform/utils';
import { devicePixelRatio, currentBreakpoint } from 'components/vendor/perform/responsive';

require('components/vendor/perform/lazy-loader/style.scss');

const config = {
  threshold: 300, // size of the buffer before the images are loaded
  throttle: 250, // number of ms between checking for new images in viewport
  images: ['img[data-lazy], [data-bg-lazy]', 'img[data-lazy-srcset], [data-bg-lazy-srcset]'],
};
const $window = $(window);
const allImagesSelector = config.images.join(', ');
const intersectionObserverSupported = isIntersectionObserverSupported();
const contexts = [];
let notLoadedContexts = [];
let wHeight = $window.height();
let isOnScrollInitialized = false;
let isOnBreakpointchangeInitialized = false;
let isOnOrientationchangeInitialized = false;
let intersectionObserver;

/**
 * preloads image with given url
 * @function loadImage
 * @private
 * @param {string} src - source of image to load
 * @param {Function} callback - callback to run on load
 */
function loadImage(src, callback) {
  const img = new window.Image();
  img.onload = callback;
  img.src = src;
}

/**
 * Get image src from parsed srcSet
 *
 * @function getImageSrc
 * @private
 * @param {Object} srcSet - parsed srcSet
 * @returns {string} image url
 */
function getImageSrc(srcSet) {
  let dpr = '1x';

  if (devicePixelRatio > 1) {
    dpr = '2x';
  }

  if (srcSet[currentBreakpoint]) {
    if (srcSet[currentBreakpoint][dpr]) {
      return srcSet[currentBreakpoint][dpr];
    }

    return srcSet[currentBreakpoint]['1x'];
  }

  return srcSet.default[dpr] || srcSet.default['1x'];
}

/**
 * parses sourceset string and returns more manageable array
 *
 * @function parseSrcSet
 * @private
 * @param {string} srcSet - sourceset string, pattern is:
 *  "{url} {breakpoint}@{pixelRatio}, {secondUrl} {breakpoint}@{pixelRatio}, ..."
 *  e.g. "http://localhost/img.png 320@1x, "http://localhost/img@2x.png 320@2x"
 *  {breakpoint} is optional. If you won't set it this image will be treated as default
 * @returns {Object} Object where keys are breakpoint names and values - image urls
 */
function parseSrcSet(srcSet) {
  const result = {};

  srcSet
    .trim()
    .split(',')
    .map($.trim)
    .forEach(el => {
      const split = el.split(' ').map($.trim);
      const breakpointSplit = (split[1] || '').split('@').map($.trim);
      const breakpoint = breakpointSplit[0] || 'default';
      const dpr = breakpointSplit[1] || '1x';

      if (!result[breakpoint]) {
        result[breakpoint] = {};
      }
      result[breakpoint][dpr] = split[0];
    });

  return result;
}

/**
 * Load image
 * @function load
 * @param  {Object} $image - jQuery object with the image to be loaded
 * @static
 */
function load($image) {
  if ($image.attr('data-lazy')) {
    const src = $image.attr('data-lazy');
    $image.removeAttr('data-lazy');

    loadImage(src, () => {
      onRAF(() => {
        $image.attr('src', src);
        onRAF(() => {
          $image.attr('data-state', 'loaded');
        });
      });
    });
  } else if ($image.attr('data-bg-lazy')) {
    const src = $image.attr('data-bg-lazy');
    $image.removeAttr('data-bg-lazy');

    loadImage(src, () => {
      onRAF(() => {
        $image.css('background-image', `url("${src}")`).attr('data-state', 'loaded');
      });
    });
  } else if ($image.attr('data-lazy-srcset')) {
    const srcSet = parseSrcSet($image.attr('data-lazy-srcset'));
    const src = getImageSrc(srcSet);

    if (src !== $image.attr('src')) {
      loadImage(src, () => {
        onRAF(() => {
          $image.attr('src', src);
          onRAF(() => {
            $image.attr('data-state', 'loaded');
          });
        });
      });
    } else {
      $image.attr('data-state', 'loaded');
    }
  } else if ($image.attr('data-bg-lazy-srcset')) {
    const srcSet = parseSrcSet($image.attr('data-bg-lazy-srcset'));
    const src = getImageSrc(srcSet);

    if (`url("${src}")` !== $image.css('background-image')) {
      loadImage(src, () => {
        onRAF(() => {
          $image.css('background-image', `url("${src}")`).attr('data-state', 'loaded');
        });
      });
    } else {
      $image.attr('data-state', 'loaded');
    }
  }
}

/**
 * Remove element from array
 * @function removeElementFromArray
 * @param {Array} array - array from which element will be removed
 * @param {Object} element - element to remove
 * @static
 * @returns {Array} - returns reference to the passed array
 */
function removeElementFromArray(array, element) {
  const index = array.indexOf(element);

  if (index !== -1) {
    array.splice(index, 1);
  }

  return array;
}

/**
 * Search for not loaded images visible within the threshold of a viewport and load them
 */
/**
 * check if images should be loaded, and load if they should
 * @function check
 * @param {Object} context - context object which should be searched
 * @static
 */
function check(context = document) {
  const $images = $(context)
                    .find(allImagesSelector)
                    .filter('[data-state="notloaded"]');

  if ($images.length === 0) {
    removeElementFromArray(notLoadedContexts, context);
    return;
  }

  if (intersectionObserverSupported) {
    $images
      .each((key, image) => {
        getIntersectionObserver().observe(image);
      });
  } else {
    const currentBottom = wHeight + $window.scrollTop() + config.threshold;

    onRAF(() => {
      $images
        .each((key, image) => {
          const $image = $(image);
          const position = $image.offset().top;

          if (position < currentBottom) {
            load($image);
          }
        });
    });
  }
}

/**
 * Check if IntersectionObserver API is supported by browser
 *
 * @function isIntersectionObserverSupported
 * @private
 * @returns {boolean} true/false
 */
function isIntersectionObserverSupported() {
  return 'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype;
}

/**
 * Add isIntersecting property if it's not available and IntersectionObserver is supported
 *
 * @function addIsIntersectingPolyfill
 * @private
 */
function addIsIntersectingPolyfill() {
  if (!intersectionObserverSupported) {
    return;
  }

  // Minimal polyfill for Edge's/IE's lack of `isIntersecting`
  if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
    Object.defineProperty(window.IntersectionObserverEntry.prototype,
      'isIntersecting', {
        get() {
          return this.intersectionRatio > 0;
        },
      }
    );
  }
}

/**
 * Create IntersectionObserver instance
 *
 * @function createIntersectionObserver
 * @private
 * @returns {Object} IntersectionObserver instance
 */
function getIntersectionObserver() {
  if (intersectionObserver) {
    return intersectionObserver;
  }

  const options = {
    rootMargin: `${config.threshold}px`,
  };

  intersectionObserver = new window.IntersectionObserver((entries, entriesObserver) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        load($(entry.target));
        entriesObserver.unobserve(entry.target);
      }
    });
  }, options);

  return intersectionObserver;
}

/**
 * Initialize Lazy Loading
 *
 * @function init
 * @param {Object} context - context object which should be searched
 * @static
 */
function init(context = document) {
  const $images = $(context).find(allImagesSelector);
  contexts.push(context);
  notLoadedContexts.push(context);
  $images
    .attr('data-state', 'notloaded')
    .attr('data-component', 'lazyloading');
  addIsIntersectingPolyfill();
  check(context);

  if (!intersectionObserverSupported) {
    if (is('operamini')) {
      $images.each((key, image) => {
        load($(image));
      });
    } else if (!isOnScrollInitialized) {
      $window.on('scroll', throttle(() => {
        notLoadedContexts.forEach(ctx => {
          check(ctx);
        });
      }, config.throttle));
      isOnScrollInitialized = true;
    }

    if (!isOnBreakpointchangeInitialized) {
      $window.on('breakpointchange', () => {
        wHeight = $window.height();
        notLoadedContexts = [...contexts];
        contexts.forEach(ctx => {
          $(ctx)
            .find(allImagesSelector)
            .attr('data-state', 'notloaded');
          check(ctx);
        });
      });
      isOnBreakpointchangeInitialized = true;
    }

    if (!isOnOrientationchangeInitialized) {
      $window.on('orientationchange', () => {
        wHeight = $window.height();
      });
      isOnOrientationchangeInitialized = true;
    }
  }
}

export {
  init,
  check,
  load,
};
