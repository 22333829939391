import { local as localStorage } from 'components/vendor/perform/storage';
import { pubsubNamespace } from 'components/vendor/perform/utils';
import pubsub from 'pubsub.js';

import 'widgets/legacy-link-banner/style.scss';

/**
 * Legacy link banner widget module
 * @param {Object} context - context object
 */
export default function (context) {
  const $context = $(context);
  const classWidget = 'widget-legacy-link-banner';
  const selectorWidget = `.${classWidget}`;
  const classWidgetHidden = `${classWidget}--hidden`;
  const selectorAcceptBtn = `${selectorWidget}__accept`;
  const storageKeyAccepted = `${classWidget}.accepted`;
  const $acceptBtn = $context.find(selectorAcceptBtn);
  const eventNamespaceLegacyLinkBanner = pubsubNamespace($context);

  /**
   * Toggle banner visibility based on storage data
   */
  function toggleBanner() {
    const isBannerVisible = localStorage.get(storageKeyAccepted);
    $context.toggleClass(classWidgetHidden, isBannerVisible);
    pubsub.publish(`${eventNamespaceLegacyLinkBanner}/toggle-visibility`, [!isBannerVisible]);
  }

  $acceptBtn.on('click', () => {
    localStorage.set(storageKeyAccepted, true);
    toggleBanner();
    pubsub.publish(`${eventNamespaceLegacyLinkBanner}/toggle-visibility`, [false]);
    alert($context.data('alert')); // eslint-disable-line no-alert
  });

  toggleBanner();
}
