import { push as pushToGTM } from 'assets/components/gtm';

const GA_NAMESPACE = 'ga';
const GA_EVENT_NAME = `${GA_NAMESPACE}.event`;

const GA_ITEM_SELECTOR = `[data-${GA_NAMESPACE}-category][data-${GA_NAMESPACE}-action]\
[data-${GA_NAMESPACE}-label]:not([data-${GA_NAMESPACE}-category=""])\
:not([data-${GA_NAMESPACE}-action=""]):not([data-${GA_NAMESPACE}-label=""])`;

const GA_DOM_EVENTS = `click.${GA_NAMESPACE}`;

const $body = $('body');
let listenerAdded = false;
let disallowedEvents;

/**
 * Check if GA event and category is allowed
 * @param {string} category - GA event category
 * @param {string} action - GA event action
 * @returns {bool} - true if event is allowed
 */
function eventIsAllowed(category, action) {
  return false;
  return !disallowedEvents || !disallowedEvents[category] || !disallowedEvents[category][action];
}

/**
 * Default click event handler
 * @param {Event} event - jQuery event object
 */
function eventHandler(event) {
  const $item = $(event.target).closest(GA_ITEM_SELECTOR);
  const category = $item.data('gaCategory');
  const action = $item.data('gaAction');
  const label = $item.data('gaLabel');

  if (!eventIsAllowed(category, action)) {
    return;
  }

  pushEvent(category, action, label);
}

/**
 * Push event to GTM to trigger GA event
 * @param {string|Object} category - GA event category or object with category,
 * action, label and value properties
 * @param {string} action - GA event action
 * @param {string} label - GA event label
 * @returns {Function|undefined} - partial function or undefined if all arguments
 * where passed to function
 */
export function pushEvent(category, action, label) {
  if ($.isPlainObject(category)) {
    ({ category, action, label } = category);
  }

  if (category && action && label) {
    pushToGTM(GA_EVENT_NAME, {
      [`${GA_NAMESPACE}.category`]: category,
      [`${GA_NAMESPACE}.action`]: action,
      [`${GA_NAMESPACE}.label`]: label,
    });
  } else if (!action) {
    return pushEvent.bind(null, category);
  } else if (!label) {
    return pushEvent.bind(null, category, action);
  }

  return undefined; // Linter needs this
}

/**
 * Helper function used to push GA events according to passed selector
 * to GA event data mapping
 * @param {Object} eventsMapping - selectors to GA event data mapping in format
 * { selector: {category: 'cateogry',[...]}, [...] }
 * @param {Function} callback - callback function with three arguments:
 * matchedSelector, jQuery $item object and eventData object
 * @param {string} [events='click.ga'] - events to listen on
 */
export function applyEventsMap(eventsMapping, callback, events = GA_DOM_EVENTS) {
  const selectors = Object.keys(eventsMapping);

  $body.on(events, selectors.join(','), event => {
    const $target = $(event.target);

    for (let index = 0; index < selectors.length; index++) {
      const selector = selectors[index];
      const $item = $target.closest(selector);
      const eventData = eventsMapping[selector];

      if ($item.length) {
        if (!callback || callback(selector, $item, eventData)) {
          pushEvent(eventData);
        }

        break;
      }
    }
  });
}

/**
 * Initialize global GA events functionality, use data-ga-[category|action|label]
 * attributes to mark elements on which listen for click events. Function can be
 * called multiple times to update allowed events.
 * @param {Object} disallowEvents - dictionary of allowed events, don't pass
 * this argument to allow all events
 */
export function init(disallowEvents) {
  disallowedEvents = disallowEvents;

  if (!listenerAdded) {
    $body.on(GA_DOM_EVENTS, GA_ITEM_SELECTOR, eventHandler);
    listenerAdded = true;
  }
}
