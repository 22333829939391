/**
 * Utils module
 * @namespace vendor/perform/components/utils
 */

import UAParser from 'ua-parser-js';
import MobileDetect from 'mobile-detect';

let lastTime = 0;
let rtl = null;
let uaparser = null;
let result = null;
let browserVersion = null;
let browserName = null;
let modelName = null;
let vendorName = null;

/**
 * Will run function on RequestAnimationFrame
 *
 * @function onRAF
 * @static
 * @param {Function} callback - callback to run on RAF
 */
function onRequestAnimationFrame(callback) {
  const currTime = new Date().getTime();

  if (!window.requestAnimationFrame) {
    const timeToCall = Math.max(0, 16 - currTime - lastTime);

    window.setTimeout(callback, timeToCall);
    lastTime = currTime + timeToCall;
  } else {
    window.requestAnimationFrame(callback);
  }
}

/**
 * random id generator
 *
 * @function generateUUID
 * @returns {string} UUID-like random string
 */
function guid() {
  let d = new Date().getTime();

  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = ((d + Math.random()) * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
  });

  return uuid;
}

/**
 * checks whether page is RTL
 *
 * @function isRTL
 * @static
 * @returns {boolean} is page RTL
 */
function isRTL() {
  if (rtl === null) {
    rtl = document.documentElement.getAttribute('dir') === 'rtl';
  }

  return rtl;
}

/**
 * Throttles given function execution (does not run it no faster then ...)
 * @function throttle
 *
 * @static
 * @param {Function} fn - function to throttle
 * @param {number} [threshhold=250] - how long the throttle
 * @param {Object} [scope=this] - context
 * @returns {Function} throttled function
 */
function throttle(fn, threshhold = 250, scope = null) {
  let last;
  let deferTimer;

  return function throttled(...args) {
    const context = scope || this;
    const now = +new Date();

    if (last && now < last + threshhold) { // hold on to it
      window.clearTimeout(deferTimer);

      deferTimer = window.setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

/**
 * Debounces given function execution (delays)
 *
 * @function debounce
 * @static
 *
 * @param {Function} fn - function to debounce
 * @param {number} [delay=250] - debounce delay duration
 * @returns {Function} debounced function
 */
function debounce(fn, delay = 250) {
  let timer = null;

  return function debounced(...args) {
    const that = this;
    window.clearTimeout(timer);

    timer = window.setTimeout(() => {
      fn.apply(that, args);
    }, delay);
  };
}

/**
 * Init user agent parser with cache of browser and device name
 *
 * @private
 * @returns {Object} UAParser instance
 */
function uaParserInstance() {
  if (!uaparser) {
    const sanitize = (name) => name.toLowerCase().replace(/[^a-z0-9]/g, '');

    uaparser = new UAParser();
    result = uaparser.getResult();

    const md = new MobileDetect(window.navigator.userAgent);

    if (typeof (md.phone()) === 'string') {
      result.device.type = 'mobile';
    } else if (typeof (md.tablet()) === 'string') {
      result.device.type = 'tablet';
    }

    browserName = sanitize(result.browser.name || '');
    browserVersion = result.browser.major;
    vendorName = sanitize(result.device.vendor || '');
    modelName = sanitize(result.device.model || '');

    if (window.navigator.userAgent.match(/ucweb.+(ucbrowser)[\/\s]?([\w\.]+)/i) ||
      window.navigator.userAgent.match(/(uc\s?browser)[\/\s]?([\w\.]+)/i) ||
      window.navigator.userAgent.match(/juc.+(ucweb)[\/\s]?([\w\.]+)/i)) {
      browserName = 'ucbrowser';
    }

    if (browserName === 'chrome' && parseInt(browserVersion, 10) < 40) {
      browserName = 'androidbrowser';
    }
  }

  return result;
}

/**
 * is
 * @function is
 * @static
 *
 * @param {(string|string[])} name - browserstring lowercased and w/o spaces
 * @returns {boolean} is it
 */
function isBrowser(name) {
  if (Array.isArray(name)) {
    return name.some((e) => isBrowser(e));
  }

  uaParserInstance();
  return [browserName, `${browserName} - ${browserVersion}`, vendorName, modelName]
      .indexOf(name) !== -1;
}

/**
 * Get all info about device
 *
 * @function getDeviceInfo
 * @static
 * @returns {Object} all information about device based on user agent
 */
function getDeviceInfo() {
  return uaParserInstance();
}

/**
 * Append browser name to element
 *
 * @function appendBrowserName
 * @static
 * @param {string} prefix - prefix for browser name (default: ua-)
 * @param {string} sel - selector for the element to add browser name (default: html)
 */
function appendBrowserName(prefix, sel) {
  uaParserInstance();
  prefix = prefix || 'ua-';
  sel = sel || 'html';
  $(sel).addClass(`${prefix}${browserName}`);
  $(sel).addClass(`${prefix}${browserName}-${browserVersion}`);
  $(sel).addClass(`${prefix}vn-${vendorName}`);
}

/**
 * Assigns own enumerable properties of source object to the destination object
 * for all destination properties that resolve to undefined
 *
 * @function defaults
 * @param {Object} destination - The destination object
 * @param {Object} source - The source object
 * @returns {Object} destination object
 */
function defaults(destination, source) {
  Object.keys(source).forEach((key) => {
    if (typeof destination[key] !== 'undefined') {
      destination[key] = source[key];
    }
  });
  return destination;
}

/**
 * Assigns own enumerable properties of source object, that donâ€™t resolve to undefined
 * into the destination object
 *
 * @function merge
 * @param  {Object} destination - The destination object
 * @param  {Object} source - The source object
 * @returns {Object} destination object
 */
function merge(destination, source) {
  Object.keys(source).forEach((key) => {
    if (typeof source[key] !== 'undefined') {
      destination[key] = source[key];
    }
  });
  return destination;
}

/**
 * Rewrite tag name to the given new tag name
 *
 * @function rewriteTagName
 * @param {jQuery} $selector - jQuery colletion for tags to rename
 * @param {string} newTagName - new name for a tag
 */
function rewriteTagName($selector, newTagName) {
  if ($selector.length) {
    $selector.each(function rewriteItemTagName() {
      const tag = $(this).get(0);
      const newTag = document.createElement(newTagName);

      newTag.text = tag.innerHTML;

      for (let i = tag.attributes.length - 1; i >= 0; i--) {
        newTag.setAttribute(tag.attributes[i].name, tag.attributes[i].value);
      }

      tag.parentNode.replaceChild(newTag, tag);
    });
  }
}

/**
 * Generate PubSub namespace
 *
 * @param {HTMLElement|jQuery} context - Module context
 * @returns {string} - PubSub namespace
 */
function pubsubNamespace(context) {
  const moduleName = $.trim($(context).data('module'));

  if (!moduleName) {
    return 'core';
  }

  return moduleName.split(/\s+/)[0].replace(/\//g, '-');
}

export {
  throttle,
  debounce,
  onRequestAnimationFrame as onRAF,
  guid as generateUUID,
  isRTL,
  isBrowser as is,
  getDeviceInfo,
  appendBrowserName,
  defaults,
  merge,
  rewriteTagName,
  pubsubNamespace,
};
