/**
 * Responsive module
 * @namespace vendor/perform/components/responsive
 */

import { info } from 'components/vendor/perform/core';
import { is } from 'components/vendor/perform/utils';

import pubsub from 'pubsub.js';

const fullscreenEnterCallbacks = [];
const fullscreenExitCallbacks = [];
const allMappings = [
  [
    'requestFullscreen',
    'exitFullscreen',
    'fullscreenElement',
    'fullscreenEnabled',
    'fullscreenchange',
    'fullscreenerror',
  ],
  [
    'webkitRequestFullscreen',
    'webkitExitFullscreen',
    'webkitFullscreenElement',
    'webkitFullscreenEnabled',
    'webkitfullscreenchange',
    'webkitfullscreenerror',

  ],
  [
    'webkitRequestFullScreen',
    'webkitCancelFullScreen',
    'webkitCurrentFullScreenElement',
    'webkitCancelFullScreen',
    'webkitfullscreenchange',
    'webkitfullscreenerror',

  ],
  [
    'mozRequestFullScreen',
    'mozCancelFullScreen',
    'mozFullScreenElement',
    'mozFullScreenEnabled',
    'mozfullscreenchange',
    'mozfullscreenerror',
  ],
  [
    'msRequestFullscreen',
    'msExitFullscreen',
    'msFullscreenElement',
    'msFullscreenEnabled',
    'MSFullscreenChange',
    'MSFullscreenError',
  ],
];
const devicePixelRatio = getDevicePixelRatio();

let breakpointsList = {
  320: [320, 479],
  480: [480, 579],
  580: [580, 739],
  740: [740, 979],
  980: [980, 1579],
  1580: [1580, null],
};

let noAnimationTo = null;
let initialized = false;
let check = null;
let previousWidth = null;
let fnMap = null;
/* eslint-disable import/no-mutable-exports */
let currentBreakpoint;
/* eslint-enable import/no-mutable-exports */

/**
 * onfScreenChange
 *
 */
function onfScreenChange() {
  let isFullScreen = null;

  if (fnMap !== null) {
    isFullScreen = Boolean(document[fnMap[2]]);
  }

  if (isFullScreen === true) {
    fullscreenEnterCallbacks.forEach((cb) => cb());
  } else if (isFullScreen === false) {
    fullscreenExitCallbacks.forEach((cb) => cb());
  }
}

/**
 *
 * matchMedia wrapper that's cross-browser, just pass the media query
 *
 * @function matchMedia
 * @param {string} mediaQuery - media query to check
 * @returns {boolean} matches media
 */
function matchesMedia(mediaQuery) {
  let styleMedia;
  let styleInfo;

  if (window.matchMedia) {
    return window.matchMedia(mediaQuery).matches;
  }

  styleMedia = (window.styleMedia || window.media);

  if (!styleMedia) {
    const style = document.createElement('style');
    const script = document.getElementsByTagName('script')[0];
    styleInfo = null;

    style.type = 'text/css';
    style.id = 'matchmediajs-test';

    script.parentNode.insertBefore(style, script);

    styleInfo = ('getComputedStyle' in window && window.getComputedStyle(style, null))
      || style.currentStyle;

    styleMedia = {
      matchMedium: (media) => {
        const text = `@media ${media}{ #matchmediajs-test { width: 1px; } }`;

        if (style.styleSheet) {
          style.styleSheet.cssText = text;
        } else {
          style.textContent = text;
        }

        return styleInfo.width === '1px';
      },
    };
  }

  return styleMedia.matchMedium(mediaQuery);
}

/**
 * enters fullscreen mode if able
 * @param {jQuery|HTMLElement} [element] - element to make fullscreen
 * @function enterFullscreen
 */
function enterFullscreen(element) {
  element = element || document.documentElement;

  if (is(['operamini', 'androidbrowser'])) {
    return;
  }

  if (element instanceof $) {
    element = element.get(0);
  }

  if (fnMap !== null && element[fnMap[0]]) {
    element[fnMap[0]]();
  }
}

/**
 * leaves fullscreen
 * @function exitFullscreen
 */
function exitFullscreen() {
  if (is(['operamini', 'androidbrowser'])) {
    return;
  }

  if (fnMap !== null && document[fnMap[1]]) {
    document[fnMap[1]]();
  }
}

/**
 * @function breakpoint
 *
 * @param {string} operator - operator, oneof ['at', 'from', 'to', 'between']
 * @param {string} bNameFrom - breakpoint name
 * @param {string} [bNameTo] - second breakpoint name, when using between
 * @returns {boolean} whether is at breakpoint or none
 */
function bpFunction(operator, bNameFrom, bNameTo) {
  const possibleOperators = ['at', 'from', 'to', 'between'];
  const breakpointNames = Object.keys(breakpointsList);
  let bTo;
  let mq;

  if (breakpointNames.indexOf(bNameFrom) === -1) {
    throw `Breakpoint ${bNameFrom} does not exist in config`;
  }

  if (possibleOperators.indexOf(operator) === -1) {
    throw `Operator needs o be one of: ${possibleOperators.join(', ')} ${operator} given`;
  }

  if (operator === 'between') {
    if (bNameTo && breakpointNames.indexOf(bNameTo) === -1) {
      throw 'Between operator needs 2 breakpoint names';
    } else {
      bTo = breakpointsList[bNameTo];
    }
  }

  const bFrom = breakpointsList[bNameFrom];

  if (operator === 'at') {
    if (bFrom[1] === null) {
      mq = `(min-width: ${bFrom[0]}px)`;
    } else {
      mq = `(min-width: ${bFrom[0]}px) and (max-width: ${bFrom[1]}px)`;
    }
  } else if (operator === 'from') {
    mq = `(min-width: ${bFrom[0]}px)`;
  } else if (operator === 'to') {
    mq = `(max-width: ${bFrom[0] - 1}px)`;
  } else if (operator === 'between') {
    mq = `(min-width: ${bFrom[0]}px) and (max-width: ${bTo[0] - 1}px)`;
  }

  return matchesMedia(mq);
}

/**
 * getCurrentBreakpoint
 * @returns {string} breakpoint name
 * @private
 *
 */
function getCurrentBreakpoint() {
  const breakpointNames = Object.keys(breakpointsList);

  for (let i = 0, len = breakpointNames.length; i < len; i++) {
    if (bpFunction('at', breakpointNames[i])) {
      return breakpointNames[i];
    }
  }

  return null;
}

/**
 * getDevicePixelRatio
 * @returns {string} breakpoint name
 * @private
 *
 */
function getDevicePixelRatio() {
  if (window.screen.systemXDPI !== undefined
    && window.screen.logicalXDPI !== undefined
    && window.screen.systemXDPI > window.screen.logicalXDPI) {
    return window.screen.systemXDPI / window.screen.logicalXDPI;
  }

  if (window.devicePixelRatio !== undefined) {
    return window.devicePixelRatio;
  }

  return 1;
}

/**
 * Event fired on window object when the breakpoint changes
 * @event breakpointchange
 * @type {Object}
 * @property {string} current current breakpoint
 * @property {string} previous previous breakpoint
 */

/**
 * Event published through pubSub when the breakpoint changes
 * @event responsive/breakpointchange
 * @param {string} current current breakpoint
 * @param {string} previous previous breakpoint
 */

/**
 * responsive initializer, does the following
 *  - adds no-animate class to html, which should block all transitions
 *    during resize
 *
 *  @function init
 *  @fires breakpointchange
 *  @fires responsive/breakpointchange
 *  @param {Object} [overrideBreakpointsList] - Override default breakpoints list
 */
function init(overrideBreakpointsList) {
  if (initialized) {
    return;
  }

  initialized = true;
  breakpointsList = overrideBreakpointsList || breakpointsList;

  for (let i = 0, len = allMappings.length; i < len; i++) {
    check = allMappings[i];

    if (check && check[0] in document.documentElement) {
      fnMap = check;
      break;
    }
  }

  if (fnMap !== null) {
    $(document).on(fnMap[4], () => {
      onfScreenChange();
    });
  }

  const atStartAndResize = () => {
    const previousBreakpoint = currentBreakpoint;
    let ev;

    currentBreakpoint = getCurrentBreakpoint();

    if ([currentBreakpoint, previousBreakpoint].indexOf(null) === -1
      && previousBreakpoint !== currentBreakpoint) {
      /* eslint-disable new-cap */
      ev = $.Event('breakpointchange');
      /* eslint-enable new-cap */
      ev.current = currentBreakpoint;
      ev.previous = previousBreakpoint;

      $(window).trigger(ev);
      pubsub.publish('responsive/breakpointchange', [ev.current, ev.previous]);
      info(
        `Breakpoint changed from: ${previousBreakpoint} to: ${currentBreakpoint}`
      );
    }
  };

  const onResizeX = () => {
    atStartAndResize();

    $('html').addClass('no-animate');
    clearTimeout(noAnimationTo);
    noAnimationTo = setTimeout(() => {
      $('html').removeClass('no-animate');
    }, 1000);
  };

  $(window).on('resize-x', onResizeX);

  atStartAndResize();


  /**
   * Event published through pubSub when window width changes
   * @event responsive/resize-x
   */

  /**
   * Event published through pubSub when window height changes
   * @event responsive/resize-y
   */

  const onResize = () => {
    const currentWidth = parseInt($(window).width(), 10);

    /* eslint-disable new-cap */
    if (previousWidth !== null && previousWidth !== currentWidth) {
      $(window).trigger($.Event('resize-x'));
      pubsub.publish('responsive/resize-x');
    } else {
      $(window).trigger($.Event('resize-y'));
      pubsub.publish('responsive/resize-y');
    }
    /* eslint-enable new-cap */

    previousWidth = currentWidth;
  };

  onResize();

  $(window).on('resize', onResize);
}

/**
 * onFullscreenEnter
 *
 * @param {Function} callback - callback
 */
function onFullscreenEnter(callback) {
  fullscreenEnterCallbacks.push(callback);
}

/**
 * onFullscreenExit
 *
 * @param {Function} callback - callback
 */
function onFullscreenExit(callback) {
  fullscreenExitCallbacks.push(callback);
}

/**
 * defocusSelection
 *
 */
function defocusSelection() {
  $('#defocus-element').focus();
}

export {
  currentBreakpoint,
  devicePixelRatio,
  defocusSelection,
  bpFunction as breakpoint,
  onFullscreenExit,
  onFullscreenEnter,
  enterFullscreen,
  exitFullscreen,
  init,
};
