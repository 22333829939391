import globalPubsub from 'pubsub.js';
import 'components/loader/style.scss';

const loaderClass = 'component-loader';
const loaderDefaultClass = `${loaderClass}--default`;
const loaderLoadMoreClass = `${loaderClass}--load-more`;
const loaderActiveClass = `${loaderClass}--active`;
const ignoreSelector = '[data-loader-ignore]';
const disableSelector = '[data-loader-disable]';
const loadMoreSelector = '[data-loader-load-more]:visible';
let initialized = false;

/**
 * Check if context is available for automatic loader adding
 * @param {Function} callback - function to call if context is valid
 * @param {HTMLElement} context - context to check
 */
function validate(callback, context) {
  if (!$(context).is(ignoreSelector)) {
    callback(context);
  }
}

/**
 * Toggle loader
 * @param {HTMLElement} context - context
 * @param {boolean} [showLoader=true] - show or hide loader
 */
function toggle(context, showLoader = true) {
  const $context = $(context);

  if ($context.is(disableSelector)) {
    return;
  }

  const hasLoadMoreButton = !!$context.find(loadMoreSelector).length;
  $context.addClass(loaderClass);

  if (showLoader) {
    $context
      .toggleClass(loaderLoadMoreClass, hasLoadMoreButton)
      .toggleClass(loaderDefaultClass, !hasLoadMoreButton);
  }

  $context.toggleClass(loaderActiveClass, showLoader);
}

/**
 * Show loading indicator
 * @param {HTMLElement} context - context
 */
function show(context) {
  toggle(context, true);
}

/**
 * Hide loading indicator
 * @param {HTMLElement} context - context
 */
function hide(context) {
  toggle(context, false);
}

/**
 * Init loading indicator
 * @param {pubsub.js} pubsub - pubsub instance (defaults to global)
 */
function init(pubsub = globalPubsub) {
  if (initialized) {
    return;
  }

  pubsub.subscribe('*/ajax/before', validate.bind(null, show));
  pubsub.subscribe('*/ajax/after', validate.bind(null, hide));

  initialized = true;
}

export {
  init,
  show,
  hide,
};
