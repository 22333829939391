import dropdown from 'components/dropdown';

/**
 * Dropdown widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - settings
 */
export default function (context, settings = {}) {
  settings.needWrapping = false;
  dropdown(context, settings);
}
