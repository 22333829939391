export default {
  /**
   * On event append event
   * @param {string} eventName string event name
   * @param {function} callback fn call when emit event
   * @returns {object} this
   */
  on: function (eventName, callback) {
    this._events[eventName] = this._events[eventName] || [];
    this._events[eventName].push({
      fn: callback
    });

    return this;
  },
  /**
   * Off event remove event
   * @param {string} eventName string event name
   * @returns {object} this
   */
  off: function (eventName) {
    var self = this;

    Object.keys(this._events).forEach(function (key) {
      if (eventName === key) {
        try {
          delete self._events[eventName];
        }
        catch (error) {
          throw new Error(error);
        }
      }
    });

    return this;
  },
  /**
   * Emit event with data
   * @param {string} event name
   * @param {string|object|number} all arguments call for callback event
   * @returns {this} object
   */
  emit: function () {
    var args = Array.apply([], arguments);
    var list = this._events[args.shift()] || [];

    for (var i = 0, j; (j = list[i++]);) {
      j.fn.apply(this, args);
    }

    return this;
  }
};
