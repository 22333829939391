import eventsDomEmit from './events-dom-emit';
import events from './events';
import deepExtend from 'deep-extend';

const defaults = {
  // holder to emit dom event
  eventDomHolder: 'body',
  // container to append screenSet
  forceProvidersLogout: false,
  // url request to backend
  // log user action to GTM etc.
  logUserEvent: true,
};

/**
 * UserAccount library
 * @param options {object} options to extend defaults
 * @param adapterLogUserEvent {function} optional adapter to log user actions
 * @constructor
 */
function UserAccount(options, adapterLogUserEvent) {
  this._isLoad = false;
  this._events = {};
  this._isLoggedIn = false;
  this._profileData = false;
  this._queue = [];
  this._queuePromise = {};
  this._tmpSocialProviders = null;
  this.options = deepExtend({}, defaults, options);
  this.logAdapter = adapterLogUserEvent || null;
}

UserAccount.prototype = {
  constructor: UserAccount,
  /**
   * Show screen set on user login
   * @param {object} params optional extend default option screenSet
   */
  login: function (params) {
    $('#dialog-login').prependTo('body').fadeIn();
  },
  /**
   * Logout user
   * @param {object} params optional default options
   */
  logout: function (params) {
    localStorage.removeItem('__user_likes__');
    let parts = ('; ' + document.cookie).split('; mk-token=');
    if (parts.length === 2) {
      document.cookie = "mk-token=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
    parts = ('; ' + document.cookie).split('; mk-rtoken=');
    if (parts.length === 2) {
      document.cookie = "mk-rtoken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  },
  /**
   * Check logged user
   *
   * @returns {boolean}
   */
  checkLoggedIn: function () {
    var cookie = window.document.cookie || '';

    this._isLoggedIn = cookie
      .split('; ')
      .some(function (value) {
        return value.indexOf('mk-token') !== -1;
      });

    return this._isLoggedIn;
  },
  /**
   * Request on API in backend
   * @param {object} params optional extend default options request ajax
   * @emits UserAccount#error
   * @returns {Promise}
   * @private
   */
  _requestApi: function (params) {
    var self = this;

    var requestParams = deepExtend({}, {
      credentials: 'include',
      method: 'post',
      headers: {
        Accept: 'application/json, application/xml, text/play, text/html',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: 0
      }
    }, params);

    return fetch(this.options.urlRestApi, requestParams)
      .then(function (response) {
        if (response.status >= 200 && response.status < 300) {
          return response;
        }

        var error = {
          errorCode: response.status,
          errorMessage: (response.statusText || 'unknown error')
        };

        // emit event
        self.emit('error', error);
        // emit event dom
        eventsDomEmit(self._getEventDomHolder(), 'error.UserAccount', error);

        return response;
      })
      .catch(function (error) {
        // emit event
        self.emit('error', error);
        // emit event dom
        eventsDomEmit(self._getEventDomHolder(), 'error.UserAccount', error);

        return error;
      });
  },
  /**
   * Return dialog name
   * @param {string} name require name of screenSet
   * @returns {string}
   * @private
   */
  _getDialogName: function (name) {
    if (this.options.paramsScreenSet[name] === 'undefined') {
      throw new Error('@lib/userAccount: incorrect name (' + name + ') dialog.');
    }

    return this.options.themeScreenSet + '-' + this.options.paramsScreenSet[name].name;
  },
  /**
   * Returns event dom holder
   * @returns {DOMElement|boolean}
   * @private
   */
  _getEventDomHolder: function () {
    var element = window.document.querySelector(this.options.eventDomHolder);

    return (typeof element !== 'undefined' && element !== null ? element : false);
  },
  /**
   * Search social provider
   * @params {string} social provider
   */
  _searchSocialProvider: function (data) {
    var provider = data.split(',');

    return (provider.length > 0 ? provider[0] : provider);
  },
  /**
   * Handle event login
   * @param {object} response
   * @param {object} location
   * @emits UserAccount#login
   * @returns {boolean}
   * @private
   */
  _onLogin: function () {
    // check cookie session
    if (this.checkLoggedIn()) {
      // emit event
      this.emit('login');
      // emit event dom
      eventsDomEmit(this._getEventDomHolder(), 'login.UserAccount', null);
    }
  },
  /**
   * Handle event logout
   * @param {object} response
   * @emits UserAccount#logout
   * @returns {boolean}
   * @private
   */
  _onLogout: function (response) {
    // reset profile data
    this._profileData = false;
    // emit event
    this.emit('logout', response);
    // emit event dom
    eventsDomEmit(this._getEventDomHolder(), 'logout.UserAccount', response);
  },
  /**
   * Method Handle event error
   * @param {object} result
   * @emits UserAccount#error
   * @private
   */
  _onError: function (result) {
    // emit event
    this.emit('error', result);
    // emit event dom
    eventsDomEmit(this._getEventDomHolder(), 'error.UserAccount', result);
  },
};

// extend prototype on fn: on, off and emit
deepExtend(UserAccount.prototype, events);

export default UserAccount;
