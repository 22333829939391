/**
 * Localization module
 * @namespace vendor/perform/components/localization
 */

import { local as localStorage } from 'components/vendor/perform/storage';
import formats from 'components/vendor/perform/localization/localization-formats.json';
import moment from 'moment';

let transKeys = null;
let locationDefer = null;

/**
 * get localization format for passed country code
 * @function getFormats
 * @param {string} countryCode - ISO country code
 * @returns {string} dateFormat
 *
 */
function getFormats(countryCode) {
  const ret = {};

  Object.keys(formats.DEFAULT).forEach(key => {
    if (formats[countryCode] && formats[countryCode][key]) {
      ret[key] = formats[countryCode][key];
    } else {
      ret[key] = formats.DEFAULT[key];
    }
  });

  return ret;
}

/**
 * trans method allows getting translated strings
 *
 * @param {string} key - translation key
 * @param {Object} [replacements] - key-value objects to replace
 * @returns {string} translated string
 */
function trans(key, replacements) {
  let ret;

  if (transKeys === null) {
    transKeys = Object.keys(window.translations);
  }

  if (transKeys.indexOf(key) !== -1 && window.translations[key]) {
    ret = window.translations[key];

    if (typeof (replacements) === 'object') {
      Object.keys(replacements).forEach((rKey) => {
        ret = ret.replace(new RegExp(`:${rKey}`, 'g'), replacements[rKey]);
      });
    }

    return ret;
  }

  return `<${key}>`;
}

/**
 * @function replaceDateTimeNames
 * @param {string} str - string to replace
 * @returns {string} with replaced months
 */
function replaceDateTimeNames(str) {
  let repl = '';
  let key = '';
  let i;

  for (i = 12; i > 0; i--) {
    key = `monthShort${i}`;
    repl = trans(`components/localization.${key}`);

    str = str.replace(new RegExp(`:${key}`, 'g'), repl);

    key = `monthFull${i}`;
    repl = trans(`components/localization.${key}`);

    str = str.replace(new RegExp(`:${key}`, 'g'), repl);
  }

  for (i = 7; i > 0; i--) {
    key = `weekdayShort${i}`;
    repl = trans(`components/localization.${key}`);

    str = str.replace(new RegExp(`:${key}`, 'g'), repl);

    key = `weekdayFull${i}`;
    repl = trans(`components/localization.${key}`);

    str = str.replace(new RegExp(`:${key}`, 'g'), repl);
  }

  return str;
}

/**
 * utcToLocal
 *
 * @function utcToLocal
 * @param {number} timestampUTC - UTC timestamp
 * @private
 * @returns {moment} moment.js datetime object
 */
function utcToLocal(timestampUTC) {
  return moment.utc(timestampUTC).local();
}

/**
 * getSetting
 *
 * @param {string} keyString - string to extrapolate
 * @returns {*} setting
 */
function getSetting(keyString) {
  let ret;
  const base = window.settings;

  ret = base;
  keyString.split('.').forEach((key) => {
    ret = ret[key];
  });

  return ret;
}

/**
 * @function changePageData
 * @param {Object} data - data to change
 * @param {string} [data.url] - url to change
 * @param {Array|String} [data.keywords] - meta keywords to change
 * @param {string} [data.description] - meta description to change
 * @param {string} [data.title] - title to change
 * @param {boolean} replace - should the url be replaced or added
 */
function changePageData(data, replace) {
  const keys = Object.keys(data);
  let historyTitle;

  replace = replace || false;

  /**
   * changeOrCreateMeta
   *
   * @param {string} name - meta name
   * @param {string} value - meta value
   */
  function changeOrCreateMeta(name, value) {
    const $meta = $(`head meta[name="${name}"]`);

    if ($meta.length) {
      $meta.attr('content', value);
    } else {
      $('head').append(`<meta name="${name}" content="${value}">`);
    }
  }

  if (keys.indexOf('url') !== -1) {
    if (window.history && window.history.pushState) {
      historyTitle = (keys.indexOf('title') !== -1) ? data.title : '';
      if (replace) {
        window.history.replaceState({}, historyTitle, data.url);
      } else {
        window.history.pushState({}, historyTitle, data.url);
      }
    }
  }

  if (keys.indexOf('description') !== -1) {
    changeOrCreateMeta('description', data.description);
  }

  if (keys.indexOf('keywords') !== -1) {
    changeOrCreateMeta('keywords',
      $.isArray(data.keywords) ? data.keywords.join(',') : data.keywords);
  }

  if (keys.indexOf('title') !== -1) {
    document.title = data.title;
  }
}

/**
 * transformTime
 *
 * @param {jQuery} $timeTag - time tag
 */
function transformTime($timeTag) {
  const dateFormat = $timeTag.attr('data-dateformat') || 'auto';
  const utcTimestamp = Number($timeTag.attr('data-utc'));
  const localDateTime = utcToLocal(utcTimestamp);

  return {
    displayRelativeTime() {
      let displayTime;

      const now = moment();
      const diff = now.diff(localDateTime, 'days', true);

      if (diff >= 1) {
        displayTime = trans(
          (diff < 2 ? 'components/localization.xDay' : 'components/localization.xDays'),
          {
            days: Math.floor(diff),
          }
        );

        $timeTag.text(displayTime);
      } else {
        getUserLocation().done((location) => {
          const localizedFormats = getFormats(location);
          displayTime = replaceDateTimeNames(localDateTime.format(localizedFormats.time));
          $timeTag.text(displayTime);
        });
      }
    },
    displayLocaleTime() {
      let format = dateFormat;
      let displayTime;
      const sameDay = moment().isSame(localDateTime, 'day');

      getUserLocation().done((location) => {
        const localizedFormats = getFormats(location);

        if (format === 'auto') {
          format = (sameDay ? 'time' : 'dateShort');
        } else if (format === 'autoShort') {
          format = (sameDay ? 'time' : 'monthDay');
        }

        displayTime = replaceDateTimeNames(localDateTime.format(
          localizedFormats[format] ? localizedFormats[format] : format
        ));
        $timeTag.text(displayTime);
      });
    },
  };
}

/**
 * return user country code as a defer
 *
 * @function getUserLocation
 * @returns {Promise} defer
 *
 */
function getUserLocation() {
  if (locationDefer) {
    return locationDefer.promise();
  }

  const cbName = 'userLocationServerCallback';
  const now = new Date().getTime();
  const threshold = 24 * 60 * 60 * 1000;
  const cacheKey = 'userLocation';
  locationDefer = $.Deferred(); // eslint-disable-line new-cap
  const cached = localStorage.get(cacheKey, threshold);
  let domain = `${window.location.protocol}//geolocation.daznservices.com`;

  if (cached !== null) {
    locationDefer.resolve(cached);
    return locationDefer.promise();
  }

  $.ajax({
    url: `${domain}/page/userLocation/country/results.jsonp/callback/${cbName}/`, // eslint-disable-line max-len
    data: {
      '_': now, // eslint-disable-line quote-props
    },
    jsonp: false,
    jsonpCallback: cbName,
    dataType: 'jsonp',
  })
    .done((data) => {
      localStorage.set(cacheKey, data.location);
      locationDefer.resolve(data.location);
    })
    .fail((jqXHR, textStatus, errorThrown) => {
      localStorage.del(cacheKey);
      locationDefer.reject(jqXHR, textStatus, errorThrown);
    });

  return locationDefer.promise();
}

export {
  getUserLocation,
  replaceDateTimeNames,
  trans,
  changePageData,
  transformTime,
  utcToLocal,
  getSetting,
  getFormats,
};
