import { registerSlot } from 'components/gpt';
import { resetAdSlot } from 'widgets/livescore/renderer/wrappers.js-next';
import 'widgets/gpt-ads/style.scss';

/**
 * Ad slot widget
 * @param {HTMLElement} context - widget context
 * @param {Object} settings - widget settings
 */
export default function (context, settings) {
  resetAdSlot(context);
}
