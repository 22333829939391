import { onRAF, is, isRTL, debounce } from 'components/vendor/perform/utils';
import Hammer from 'hammerjs';
import globalPubsub from 'pubsub.js';

import 'components/tabs/style.scss';

/**
 * Header widget module
 * @param {Object} context - context object
 * @param {Object} settings - widget settings
 */
export default function (context, settings) {
  const $context = $(context);
  const classPrefix = 'component-tabs';
  const visibleClass = `${classPrefix}--visible`;
  const centerModeClass = `${classPrefix}--center-mode`;
  const distributedModeClass = `${classPrefix}--distributed-mode`;
  const isInfiniteClass = `${classPrefix}--is-infinite`;
  const forcedArrowsClass = `${classPrefix}--forced-arrows`;
  const $scrollerContainer = $context.find(`.${classPrefix}__scroller`);
  const $scrollerList = $scrollerContainer.find(`.${classPrefix}__list`);
  const arrowClass = `${classPrefix}__arrow`;
  const $arrows = $context.find(`.${arrowClass}`);
  const prevArrowClass = `${arrowClass}--prev`;
  const nextArrowClass = `${arrowClass}--next`;
  const hideArrowClass = `${arrowClass}--hide`;
  const disableArrowClass = `${arrowClass}--disabled`;
  const $prevArrow = $arrows.filter(`.${prevArrowClass}`);
  const $nextArrow = $arrows.filter(`.${nextArrowClass}`);
  const pubsub = $context.data('pubsub') || globalPubsub;
  const itemClass = `${classPrefix}__item`;
  const $scrollerItems = $scrollerList.find(`.${itemClass}`);
  const eventNamespace = 'tabs';
  const sign = isRTL() ? 1 : -1;
  const itemActiveClass = `${itemClass}--active`;
  const itemCloneClass = `${itemClass}--clone`;
  const listAnimatedClass = `${classPrefix}__list--animated`;
  const itemPrevClass = `${classPrefix}__item--prev`;
  const itemNextClass = `${classPrefix}__item--next`;
  const ghostClasses = [itemPrevClass, itemNextClass];
  const $selectedIndicator = $context.find(`.${classPrefix}__selected`);
  const $window = $(window);
  let centeredMode = false;
  let activeIndex = $scrollerItems.filter(`.${itemActiveClass}`).index();
  let scrollerIndex = activeIndex;
  let realIndex = activeIndex;
  let forceIndexChange = true;
  let movePromise = false;
  let scrollerContainerWidth;
  let $infiniteClonesPrev;
  let $infiniteClonesNext;
  let onResizeDebounced;

  const defaultSettings = {
    forceCentered: false,
    forceArrows: false,
    forceDistributed: false,
    infinite: true,
    disableSwipe: false,
    duration: 350,
  };

  const config = Object.assign({}, defaultSettings, settings);

  if (is('ucbrowser')) {
    config.infinite = false;
  }

  const clonesCount = config.infinite ? 2 : 0;

  /**
   * Distribute tabs and switch mode between distributed or centered
   */
  function distributeTabs() {
    let itemsWidth = 0;

    forceIndexChange = true;

    $scrollerItems
      .removeClass(ghostClasses.join(' '))
      .find('a')
      .css('transform', '')
    ;
    $scrollerList
      .removeClass(listAnimatedClass)
      .css('transform', '')
    ;
    $context
      .removeClass(centerModeClass)
      .removeClass(distributedModeClass)
      .removeClass(isInfiniteClass)
    ;

    scrollerContainerWidth = $scrollerContainer.width();

    if (config.forceCentered) {
      centeredMode = true;
    } else if (config.forceDistributed) {
      centeredMode = false;
    } else {
      $scrollerItems.each(function calculateItemsWidth() {
        itemsWidth += $(this).outerWidth(true);
      });

      centeredMode = itemsWidth > scrollerContainerWidth;
    }

    $context
      .toggleClass(centerModeClass, centeredMode)
      .toggleClass(distributedModeClass, !centeredMode)
      .toggleClass(isInfiniteClass, config.infinite && centeredMode)
    ;

    scrollerContainerWidth = $scrollerContainer.width();

    if (centeredMode && config.infinite) {
      createInfiniteClones();
    } else {
      removeInfniteClones();
    }

    if (centeredMode) {
      moveToIndex(activeIndex, true);
    }

    toggleArrows(activeIndex);
    positionIndicator(activeIndex);
  }

  /**
   * Remove infinite mode clones
   */
  function removeInfniteClones() {
    if ($infiniteClonesPrev) {
      $infiniteClonesPrev.remove();
      $infiniteClonesPrev = null;
    }
    if ($infiniteClonesNext) {
      $infiniteClonesNext.remove();
      $infiniteClonesNext = null;
    }
  }

  /**
   * Create infinite mode clones
   */
  function createInfiniteClones() {
    removeInfniteClones();

    $infiniteClonesPrev = $scrollerItems.slice(-clonesCount).clone();
    $infiniteClonesNext = $scrollerItems.slice(0, clonesCount).clone();

    $infiniteClonesPrev
      .add($infiniteClonesNext)
      .addClass(itemCloneClass)
      .removeClass(itemActiveClass)
    ;

    $scrollerList
      .prepend($infiniteClonesPrev)
      .append($infiniteClonesNext)
    ;
  }

  /**
   * Relocate infinite mode clones
   */
  function relocateInfiniteClones() {
    if (realIndex === scrollerIndex) {
      return;
    }

    forceIndexChange = true;
    moveToIndex(realIndex, true);
  }

  /**
   * Update previous/next tabs
   */
  function updatePrevNextItems() {
    const $allItems = $scrollerList.find(`.${itemClass}`);
    const totalIndex = scrollerIndex + clonesCount;
    const $prevItem = $allItems.eq(totalIndex + sign);
    const $nextItem = $allItems.eq(totalIndex - sign);

    $allItems
      .filter(`.${ghostClasses.join(', .')}`)
      .removeClass(ghostClasses.join(' '))
      .find('a')
      .css('transform', '')
    ;

    $prevItem.addClass(itemPrevClass);
    $nextItem.addClass(itemNextClass);

    positionPrevNextLabel($prevItem, -sign);
    positionPrevNextLabel($nextItem, sign);
  }

  /**
   * Position previous/next tab label
   * @param {jQuery} $item - tab with label to position
   * @param {number} side - side to move label (-1 or 1)
   */
  function positionPrevNextLabel($item, side) {
    const $itemLabel = $item.find('a');
    let move = ($item.outerWidth() - $itemLabel.width()) / 2;

    move *= side * -sign;

    $itemLabel.css('transform', `translate3d(${move}px, 0, 0)`);
  }

  /**
   * Clamp index
   * @param {number} index - index to clamp
   * @returns {number} - clamped index
   */
  function clampIndex(index) {
    return Math.min($scrollerItems.length - 1, Math.max(0, index));
  }

  /**
   * Modulo index (negative safe)
   * @param {number} index - index
   * @param {number} length - length
   * @returns {number} - index%length
   */
  function moduloIndex(index, length) {
    return ((index % length) + length) % length;
  }

  /**
   * Move to next index
   */
  function moveToNext() {
    const newIndex = activeIndex + 1;
    moveToIndex(newIndex);
  }

  /**
   * Move to previous index
   */
  function moveToPrevious() {
    const newIndex = activeIndex - 1;
    moveToIndex(newIndex);
  }

  /**
   * Change active tab index in centered mode
   * @param {number} newIndex - new tab index
   * @param {boolean} [noAnimate=false] - set false to disable animation
   * @returns {Promise} - Animation end promise
   */
  function moveToIndex(newIndex, noAnimate = false) {
    let moveIndex;
    /* eslint-disable new-cap */
    const deferred = $.Deferred();
    /* eslint-enable new-cap */

    noAnimate = !!noAnimate;

    if (!centeredMode || movePromise) {
      deferred.reject();
      return movePromise;
    }

    movePromise = deferred.promise();

    deferred.always(() => {
      movePromise = null;
    });

    if (!config.infinite) {
      newIndex = clampIndex(newIndex);
    }

    realIndex = moduloIndex(newIndex, $scrollerItems.length);

    if (realIndex === activeIndex && !forceIndexChange) {
      deferred.resolve();
      return movePromise;
    }

    forceIndexChange = false;

    deferred.done(() => {
      activateTab(realIndex);
    });

    scrollerIndex = newIndex;

    if (!config.infinite) {
      moveIndex = scrollerIndex;
    } else {
      moveIndex = clonesCount + scrollerIndex;
      deferred.done(relocateInfiniteClones);
    }

    toggleArrows(newIndex);

    const translateX = sign * scrollerContainerWidth * moveIndex;

    if (noAnimate) {
      $scrollerList.removeClass(listAnimatedClass);
    }

    onRAF(() => {
      $scrollerList.css('transform', `translate3d(${translateX}px, 0, 0)`);
      updatePrevNextItems();

      if (noAnimate) {
        onRAF(() => {
          $scrollerList.addClass(listAnimatedClass);
          deferred.resolve();
        });
      } else if (!is('ucbrowser')) {
        $scrollerList.on('transitionend', function onTransitionEnd(evt) {
          if (evt.target === this) {
            deferred.resolve();
            $scrollerList.off('transitionend', onTransitionEnd);
          }
        });
      } else {
        setTimeout(() => {
          deferred.resolve();
        }, config.duration + 10);
      }
    });

    return movePromise;
  }

  /**
   * Hide/show, disable/enable arrows on index or mode change
   * @param {number} newIndex - new tab index
   */
  function toggleArrows(newIndex) {
    const itemsCount = $scrollerItems.length;
    let showNext = true;
    let showPrev = true;

    if (!centeredMode) {
      $arrows.addClass(hideArrowClass);
      return;
    }

    $context.toggleClass(forcedArrowsClass, config.forceArrows);

    if (config.infinite) {
      $arrows.removeClass(hideArrowClass);
      return;
    }

    if (newIndex === 0) {
      showPrev = false;
    }

    if (newIndex === itemsCount - 1) {
      showNext = false;
    }

    $prevArrow.toggleClass(disableArrowClass, !showPrev);
    $nextArrow.toggleClass(disableArrowClass, !showNext);
  }

  /**
   * Pan end event listener
   * @param {Object} evt - event object
   */
  function onScrollerPanEnd(evt) {
    if (!centeredMode) {
      return;
    }

    const delta = evt.deltaX * sign;

    if (delta > 0) {
      moveToNext();
    } else {
      moveToPrevious();
    }
  }

  /**
   * Arrow click event listener
   * @param {Object} evt - event object
   */
  function onArrowClick(evt) {
    if (!centeredMode) {
      return;
    }

    const $arrow = $(evt.target).closest(`.${arrowClass}`);

    if ($arrow.hasClass(nextArrowClass)) {
      moveToNext();
    } else if ($arrow.hasClass(prevArrowClass)) {
      moveToPrevious();
    }
  }

  /**
   * Add scroller events
   */
  function addScrollerEvents() {
    if (!config.disableSwipe) {
      const hammer = new Hammer($scrollerContainer.get(0), {
        direction: Hammer.DIRECTION_HORIZONTAL,
        threshold: 70,
      });

      hammer.on('panend', onScrollerPanEnd);
    }

    $arrows.on('click', onArrowClick);
  }

  /**
   * Position active tab indicator
   * @param {number} tabIndex - Tab index
   */
  function positionIndicator(tabIndex) {
    const $tab = $scrollerItems.eq(tabIndex);
    $selectedIndicator.width($tab.find('a').outerWidth());

    if (!centeredMode) {
      $selectedIndicator.css('transform', `translate3d(${$tab.position().left}px, 0, 0)`);
    } else {
      $selectedIndicator.css('transform', '');
    }
  }

  /**
   * Activate tab by index
   * @param {number} tabIndex - tab index to activate
   * @param {boolean} [force=false] - flag to force active tab change
   */
  function activateTab(tabIndex, force = false) {
    force = !!force;

    if (activeIndex === tabIndex && !force) {
      return;
    }

    const $tab = $scrollerItems.eq(tabIndex);
    const $prevTab = $scrollerItems.eq(activeIndex);
    $scrollerItems.removeClass(itemActiveClass);
    $tab.addClass(itemActiveClass);

    activeIndex = tabIndex;
    positionIndicator(activeIndex);

    pubsub.publish(`${eventNamespace}/change`, [context, {
      tabId: $tab.data('tab'),
      prevTabId: $prevTab.data('tab'),
      target: $tab.data('target'),
      text: $tab.text().trim(),
    }]);
  }

  /**
   * Tab click event listener
   * @param {MouseEvent} event - mouse click event
   * @param {HTMLElement} event.target - clicked target
   * @param {boolean} event.metaKey - is meta key pressed
   * @param {boolean} event.ctrlKey - is control key pressed
   */
  function onTabClick({ target, metaKey, ctrlKey }) {
    if (!target.hasAttribute('href') ||
      target.getAttribute('href').indexOf('#') === 0 ||
      (!metaKey && !ctrlKey)) {
      const $tab = $(this).closest(`.${itemClass}`);
      const index = $tab.index();

      if (centeredMode) {
        moveToIndex(index - clonesCount);
      } else {
        activateTab(index);
      }
    }
  }

  /**
   * Window resize event listener
   */
  function onResize() {
    if ($scrollerContainer.is(':visible')) {
      onRAF(() => {
        if (movePromise) {
          movePromise.always(distributeTabs);
        } else {
          distributeTabs();
        }
      });
    }
  }

  if ($scrollerItems.length === 1) {
    $context.addClass(centerModeClass);
  } else {
    onResizeDebounced = debounce(onResize, 150);
    $window.on('resize-x', onResizeDebounced);
    onResize();
    addScrollerEvents();

    $context.addClass(visibleClass);
  }

  $scrollerContainer.on('click', `.${itemClass}`, onTabClick);
  $context.toggleClass(forcedArrowsClass, config.forceArrows);

  globalPubsub.subscribeOnce('core/rendered', () => {
    activateTab(activeIndex, true);
  });
}
