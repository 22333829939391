import 'widgets/article/single/style.scss';
import 'widgets/article/tags/style.scss';

require('widgets/responsive-picture');
require('widgets/share');

/**
 * Single article widget
 * @param {HTMLElement} context - html element
 */
export default function (context) {
  const $context = $(context);
  const classWidget = 'widget-article';
  const selectorWidget = `.${classWidget}`;
  const selectorShareButton = `${selectorWidget}__share-button`;
  const classShareBarShown = `${classWidget}--share-state`;
  const $shareButton = $context.find(selectorShareButton);

  $shareButton.on('click', () => {
    $context.toggleClass(classShareBarShown);
  });
}
