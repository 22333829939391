import { init as applyLazyLoader } from 'components/vendor/perform/lazy-loader';

import 'widgets/responsive-picture/style.scss';

/**
 * Responsive picture widget
 * @param {HTMLElement} context - html element
 */
export default function (context) {
  applyLazyLoader(context);
}
