var customEvent = function (type, data) {
  var event = document.createEvent('Event');
  event.initEvent(type, true, true);
  event.data = data;

  return event;
};

/**
 * Emit event to DOM with data
 * @param {DOMElement} element
 * @param {string} type event
 * @param {object} data
 * @returns {object|array}
 */
export default function (element, type, data) {
  if (typeof window.jQuery !== 'undefined' && typeof window.jQuery.fn.trigger !== 'undefined') {
    return window.jQuery(element).trigger(type, data);
  }

  return element.dispatchEvent && element.dispatchEvent(customEvent(type, data));
};
