import 'widgets/share/style.scss';

/**
 * Share widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - widget settings
 */
export default function (context, settings) {
  const $context = $(context);
  const classWidget = 'widget-share';
  const classOpen = `${classWidget}--open`;
  const classShareButtonOpen = 'open';
  const $shareBtn = $context
    .closest('[data-role="widget-share-parent"]')
    .find('[data-role="widget-share-click"]');
  let isOpened = false;

  /**
   * open
   */
  function open() {
    if ($shareBtn && $shareBtn.length > 0) {
      $shareBtn.addClass(classShareButtonOpen);
    }

    $context.addClass(classOpen);
    isOpened = true;
  }

  /**
   * close
   */
  function close() {
    if ($shareBtn && $shareBtn.length > 0) {
      $shareBtn.removeClass(classShareButtonOpen);
    }

    $context.removeClass(classOpen);
    isOpened = false;
  }

  /**
   * toggle
   */
  function toggle() {
    if (isOpened) {
      close();
    } else {
      open();
    }
  }

  /**
   * init share widget
   */
  function init() {
    $context.data('api', {
      open,
      close,
    });

    if (settings.openOnClick) {
      $shareBtn.on('click', event => {
        event.preventDefault();
        toggle();
      });
    }
  }

  init();
}
