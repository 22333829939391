/**
 * Storage module
 * @namespace vendor/perform/components/storage
 */

import cookies from 'js-cookie';

/**
 * Web storage API
 * @typedef {object} WebStorageApi
 */

/**
 * Handle given type methods of WebStorage
 * @private
 * @param {string} type - type of storage to use
 * @returns {Object} storage API object
 */
function webStorageApi(type) {
  const api = Object.create(null, {
    /**
     * WebStorage type is available or not
     * @property {String} enabled
     * @private
     */
    enabled: {
      value: isAvailable(),
      writable: false,
      enumerable: false,
    },

    /**
     * Pointer to the WebStorage API
     * @property {String} storage
     * @private
     */
    storage: {
      value: getStorage(),
      writable: false,
      enumerable: false,
    },

    /**
     * Clear all storage data
     * @function clear
     * @memberOf WebStorageApi#
     */
    clear: {
      value: function clear() {
        if (this.enabled) {
          this.storage.clear();
        }
      },
    },

    /**
     * Return data item value from storage using data item key
     * or null when no such key or expired
     * @function get
     * @param {String} key - data item key
     * @param {Number} [treshold] - expiry period, in ms
     * @returns {Object|null} data item value
     * @memberOf WebStorageApi#
     */
    get: {
      value: function get(key, treshold) {
        treshold = treshold || null;

        if (this.enabled) {
          const obj = JSON.parse(this.storage.getItem(key));

          if (obj) {
            if (treshold && (new Date().getTime() - obj.timestamp > treshold)) {
              return null;
            }
            return obj.value;
          }
        }

        return null;
      },
    },

    /**
     * Set data item value in the storage using data item key
     * @function set
     * @param {String} key - data item key
     * @param {String} value - data item value
     * @memberOf WebStorageApi#
     */
    set: {
      value: function set(key, value) {
        if (this.enabled) {
          const obj = {
            timestamp: new Date().getTime(),
            value,
          };

          this.storage.setItem(key, JSON.stringify(obj));
        }
      },
    },

    /**
     * Remove data item value from storage using data item key
     * @function del
     * @param {String} key - data item key
     * @memberOf WebStorageApi#
     */
    del: {
      value: function del(key) {
        if (this.enabled) {
          this.storage.removeItem(key);
        }
      },
    },
  });

  /**
   * Pointer to the WebStorage API
   * @private
   * @returns {Object} WebStorage API
   */
  function getStorage() {
    try {
      return window[type];
    } catch (e) {
      return null;
    }
  }

  /**
   * Check if this type of WebStorage API is available
   * @private
   * @returns {boolean} if is available
   */
  function isAvailable() {
    const x = '_storage_test__';

    try {
      const storage = getStorage();
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }

  return api;
}

/**
 * Web storage API
 * @typedef {object} CookieStorageApi
 */

/**
 * Handle cookie storage API
 * @private
 * @returns {CookieStorageApi} cookie storage API object
 */
function cookieApi() {
  const api = Object.create(null, {
    /**
     * cookieApi is available or not
     * @property {boolean} enabled
     * @private
     */
    enabled: {
      value: isAvailable(),
      writable: false,
      enumerable: false,
    },

    /**
     * Pointer to the cookie Api
     * @property {String} storage
     * @private
     */
    storage: {
      value: getStorage(),
      writable: false,
      enumerable: false,
    },

    /**
     * Return data item value from cookie using data item key
     * or null when no such key or expired
     * @function get
     * @param {String} key - data item key
     * @returns {Object|null} data item value
     * @memberOf CookieStorageApi#
     */
    get: {
      value: function get(key) {
        if (this.enabled) {
          return this.storage.get(key);
        }

        return null;
      },
    },

    /**
     * Set data item value in the cookie using data item key
     * @function set
     * @param {String} key - data item key
     * @param {String} value - data item value
     * @param {Number} expires - cookie expire time
     * @memberOf CookieStorageApi#
     */
    set: {
      value: function set(key, value, expires) {
        if (this.enabled) {
          this.storage.set(key, value, { expires: parseInt(expires, 10) || 365 });
        }
      },
    },

    /**
     * Remove data item value from cookie using data item key
     * @function del
     * @param {String} key - data item key
     * @memberOf CookieStorageApi#
     */
    del: {
      value: function del(key) {
        if (this.enabled) {
          this.storage.remove(key);
        }
      },
    },
  });

  /**
   * Pointer to the cookie Api
   * @private
   * @returns {CookieStorageApi|null} cookieApi
   */
  function getStorage() {
    try {
      return cookies;
    } catch (e) {
      return null;
    }
  }

  /**
   * Check if this type of cookie Api is available
   * @private
   * @returns {boolean} if is available
   */
  function isAvailable() {
    try {
      return !!window.navigator.cookieEnabled;
    } catch (e) {
      return false;
    }
  }

  return api;
}

/**
 * API object for managing local storage
 * @type {WebStorageApi}
 */
const local = webStorageApi('localStorage');
/**
 * API object for managing session storage
 * @type {WebStorageApi}
 */
const session = webStorageApi('sessionStorage');
/**
 * API object for managing cookie storage
 * @type {CookieStorageApi}
 */
const cookie = cookieApi();

export {
  local,
  session,
  cookie,
};
