import { init as coreInit, render as coreRender } from 'components/vendor/perform/core';
import { init as responsiveInit } from 'components/vendor/perform/responsive';
import { appendBrowserName, getDeviceInfo, pubsubNamespace } from 'components/vendor/perform/utils';
import { init as initLoader } from 'components/loader';
import { init as initGTM } from 'components/gtm';
import { init as initGA } from 'components/ga';
import initGPT from 'components/gpt';
import { loadScript as loadOptaScript } from 'components/opta-widget';
import pubsub from 'pubsub.js';
import clickEventListener from 'components/click-helper';

if (document.querySelectorAll('opta-widget').length) {
  loadOptaScript();
}

require('assets/sass/main.scss');
require('assets/sass/_page-content.scss');
require('widgets/gpt-ads');

let isMobileDeviceDetected = null;

const mobileAgentsDevices = new RegExp(`/Mobile|iP(hone|od|ad)|Android|BlackBerry|
  IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|
  Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/`);

/**
 * Detect if user use mobile device
 * @returns {boolean} - return true for mobile devices
 */
function isMobileDevice() {
  if (isMobileDeviceDetected === null) {
    isMobileDeviceDetected = !!navigator.userAgent.match(mobileAgentsDevices);
  }
  return isMobileDeviceDetected;
}

require('components/user-account');
require('widgets/header');
require('widgets/navigation/primary');
require('widgets/navigation/secondary');
require('widgets/footer');
require('widgets/gdpr-banner');
require('widgets/search/form');

const classPageContainer = 'page-container';
const selectorPageContainer = `.${classPageContainer}`;
const $pageContainer = $(selectorPageContainer);
const classWidgetHeader = 'widget-header';
const selectorWidgetHeader = `.${classWidgetHeader}`;
const $widgetHeader = $(selectorWidgetHeader);
const classWidgetLegacyLinkBanner = 'widget-legacy-link-banner';
const selectorWidgetLegacyLinkBanner = `.${classWidgetLegacyLinkBanner}`;
const legacyLinkBannerContext = $(selectorWidgetLegacyLinkBanner).get(0);
const eventNamespaceLegacyLinkBanner = pubsubNamespace(legacyLinkBannerContext);
const classLegacyLinkBannerModifier = '--legacy-link-banner-visible';
const classPageContainerModified = `page-container${classLegacyLinkBannerModifier}`;
const classWidgetHeaderModified = `widget-header${classLegacyLinkBannerModifier}`;
const classLayoutMasterModified = `layout-master${classLegacyLinkBannerModifier}`;
const resizeTime = 250;
const $html = $('html');
const $body = $('body');
let abTo = null;

coreInit();
appendBrowserName();
$html.addClass(`dev-${(getDeviceInfo().device.type || 'desktop')}`);

if (getDeviceInfo().os.name) {
  $html.addClass(`os-${getDeviceInfo().os.name.toLowerCase().replace(/[^a-z0-9]/g, '')}`);
}

$(window).on('resize-x', () => {
  clearTimeout(abTo);
  abTo = setTimeout(() => {
    /* eslint-disable no-unused-expressions */
    $('body').get(0).offsetHeight;
    /* eslint-enable no-unused-expressions */
  }, resizeTime);
});

pubsub.subscribe(`${eventNamespaceLegacyLinkBanner}/toggle-visibility`, state => {
  $pageContainer.toggleClass(classPageContainerModified, state);
  $widgetHeader.toggleClass(classWidgetHeaderModified, state);
  $body.toggleClass(classLayoutMasterModified, state);
});

responsiveInit(); // init the responsive tools

if (!Object.values) {
  Object.values = object => Object.keys(object).map(key => object[key]);
}

initGPT(); // Medyanet GPT ads loader
initLoader(); // init loading indicator
initGTM(); // init GTM
initGA(); // init GA

clickEventListener();

/**
 * Render page modules
 */
export default coreRender;
export { isMobileDevice };
