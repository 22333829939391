import { forEachElement } from 'components/utils/script';
import { unregisterSlot, createSlot } from 'components/gpt';
import postscribe from 'postscribe';
import { getDeviceInfo } from 'components/vendor/perform/utils';
import { info as logInfo } from 'components/vendor/perform/core';

const rows = [];
const ads = [];
const all = [];

export const TYPE_ROWS = 'rows';
export const TYPE_AD_SLOT = 'adSlot';

/**
 * Create new wrapper
 * @param {string} type - wrapper type
 * @param {*|null} data - additional data to attach
 * @returns {{visible: boolean, wrapper: HTMLDivElement, rows: Array, type: *}} - wrapper
 */
function createWrapper(type, data = null) {
  const wrapper = document.createElement('div');
  wrapper.classList.add('widget-livescore__wrapper');

  return {
    wrapper,
    rows: [],
    type,
    data,
    visible: false,
  };
}

/**
 * Get content wrappers
 * @param {Array} adSlots - list of adSlots
 * @returns {{all: Array, ads: Array, rows: Array}} - wrappers grouped by rows, ads and all
 */
export function getWrappers(adSlots) {
  if (all.length === 0) {
    forEachElement(adSlots, (adSlot) => {
      const adSlotWrapper = createWrapper(TYPE_AD_SLOT);
      const rowsWrapper = createWrapper(TYPE_ROWS);

      adSlot.classList.add('widget-livescore__ad-slot');
      adSlotWrapper.wrapper.appendChild(adSlot);

      rows.push(rowsWrapper);
      ads.push(adSlotWrapper);
      all.push(rowsWrapper, adSlotWrapper);
    });
    rows.push(createWrapper(TYPE_ROWS));
    all.push(rows[rows.length - 1]);
  } else {
    all.forEach((wrapper) => {
      wrapper.rows = [];
      wrapper.visible = false;
    });
  }

  return {
    all,
    ads,
    rows,
  };
}


/**
 * Reset ad slot to use it once again
 * @param {HTMLElement} slot - slot to reset
 */
export function resetAdSlot(slot) {
  const slotDiv = slot.querySelector('[id^="nokta/"]');
  const admostSlotDiv = slot.querySelector('[id^="admost/"]');
  const deviceInfo = getDeviceInfo().device || {};

  if (admostSlotDiv) {
    const settings = JSON.parse(slot.dataset.settings);
    let deviceType = 'desktop';
    if (deviceInfo && deviceInfo.type
      && (deviceInfo.type === 'tablet' || deviceInfo.type === 'mobile')) {
      deviceType = 'mobile';
    }
    let admostUrl = null;
    if (settings.admostUrl &&
      settings.admostUrl[deviceType]) {
      admostUrl = settings.admostUrl[deviceType];
    }
    if (settings.bannerType
      && settings.bannerType.startsWith('feed')
      && deviceType !== 'mobile') {
      if ($(window).width() < 1580) {
        return;
      }
    }
    if (admostUrl) {
      if (settings.bannerType
        && settings.bannerType.startsWith('feed')) {
        $.ajax(`${admostUrl}&nojs=1`, {
          success(response) {
            try{
            postscribe(`#${admostSlotDiv.id}`, response);
            }catch(err){}
          },
        });
      } else {
        if ($.trim($(admostSlotDiv).html()).length !== 0) {
          logInfo(`truncate ad div ${admostSlotDiv.id}`);
          $(admostSlotDiv).empty();
        }
        if ($.trim($(admostSlotDiv).html()).length === 0) {
          postscribe(`#${admostSlotDiv.id}`,
            `<script src="${admostUrl}" referrerpolicy="no-referrer-when-downgrade"></script>`);
        }
      }
    }
  } else if (slotDiv) {
    const id = slotDiv.id;
    const settings = JSON.parse(slot.dataset.settings);
    slot.removeAttribute('data-module-loaded');
    unregisterSlot(id);
    const newSlot = createSlot(settings.slotSettings, settings.defaultGroup);
    slot.appendChild(newSlot);
  }
}
