import { isMobileDevice } from 'assets/js/main.js-next';
import moment from 'moment';

const ALTERNATIVE_URL_SELECTOR = 'data-alt-href';
const MOBILE_URL_SELECTOR = 'data-mobile-href';
const JS_BLANK_SELECTOR = 'data-jsblank';
const ALTERNATIVE_URL_DATASET = 'altHref';
const MOBILE_URL_DATASET = 'mobileHref';
const JS_BLANK_DATASET = 'jsblank';
const START_TIMESTAMP_DATASET = 'startTimestamp';


/**
 * Redirect user depend of set parameters
 * @param {Event} event - mouse event
 * @param {string} defaultUrl - default url
 * @param {boolean} jsBlank - set if url needs to be opened in new tab
 * @param {string} alternativeUrl - set if page needs to be redirected to the alternative url
 * @param {string} mobileUrl - set if page needs to be redirected to the mobile url
 * @returns {boolean} - return true if no redirects needed
 */
function redirect(event, defaultUrl, jsBlank, alternativeUrl, mobileUrl) {
  if (![jsBlank, alternativeUrl, mobileUrl].some(element => element)) {
    return true;
  }

  const link = event.currentTarget;
  let url = defaultUrl;

  if (mobileUrl) {
    url = mobileUrl;
  } else if (alternativeUrl) {
    url = alternativeUrl;
  }

  link.href = url;

  if (jsBlank) {
    link.target = '_blank';
    link.rel = 'noopener';
  }

  return false;
}

/**
 * Push new attribute to array if it exists
 * @param {Object} dataset - object with data-attributes
 * @param {Array} clickHandlerArray - array with event selectors
 * @param {string} datasetValue - data-attribute short value
 * @param {string} attr - data-attribute value
 */
function clickHandlerFlag(dataset, clickHandlerArray, datasetValue, attr) {
  if (dataset[datasetValue]) {
    clickHandlerArray.push(attr);
  }
}

/**
 * Set blank page depend of user's device and set parameter
 * @param {Object} dataset - object with data-attributes
 * @returns {boolean} - return true if requirements are met
 */
function setBlankPage(dataset) {
  if (isMobileDevice() && !dataset.mobileBlank) {
    return false;
  }

  return dataset[JS_BLANK_DATASET] || false;
}

/**
 * Set alternative url depend match start time
 * @param {Object} dataset - object with data-attributes
 * @returns {boolean/string} - return alternative url if it exists
 */
function getUrlDependOfMatchStartTime(dataset) {
  const alternativeUrl = dataset[ALTERNATIVE_URL_DATASET];

  if (!alternativeUrl) {
    return false;
  }

  const startTimestamp = dataset[START_TIMESTAMP_DATASET];

  if (!startTimestamp) {
    return alternativeUrl;
  }

  let startTime = null;

  if (startTimestamp.length > 10) {
    startTime = moment(parseInt(startTimestamp, 10)).utc();
  } else {
    startTime = moment.unix(parseInt(startTimestamp, 10)).utc();
  }

  const dateNow = moment().utc();

  if (startTime.diff(dateNow, 'hours') >= 1) {
    return alternativeUrl;
  }

  return false;
}

/**
 * Set mobile url depend of user's device
 * @param {Object} dataset - object with data-attributes
 * @returns {boolean/string} - return mobile url if it exists
 */
function getUrlDependOnDevice(dataset) {
  if (!isMobileDevice()) {
    return false;
  }

  return dataset[MOBILE_URL_DATASET] || false;
}

/**
 * Listening click events and run included functions
 * @param {Object} event - mouse event
 * @param {Object} dataset - object with data-attributes
 * @param {string} url - default url
 * @returns {boolean} - return true
 */
function clickEventListener(event, dataset, url) {
  const clickHandlerArray = [];
  clickHandlerFlag(dataset, clickHandlerArray, ALTERNATIVE_URL_DATASET, ALTERNATIVE_URL_SELECTOR);
  clickHandlerFlag(dataset, clickHandlerArray, MOBILE_URL_DATASET, MOBILE_URL_SELECTOR);
  clickHandlerFlag(dataset, clickHandlerArray, JS_BLANK_DATASET, JS_BLANK_SELECTOR);

  if (!clickHandlerArray.length) {
    return true;
  }

  let jsBlank = false;
  let alternativeUrl = false;
  let mobileUrl = false;

  clickHandlerArray.forEach((flag) => {
    switch (flag) {
      case JS_BLANK_SELECTOR:
        jsBlank = setBlankPage(dataset);
        break;
      case ALTERNATIVE_URL_SELECTOR:
        alternativeUrl = getUrlDependOfMatchStartTime(dataset);
        break;
      case MOBILE_URL_SELECTOR:
        mobileUrl = getUrlDependOnDevice(dataset);
        break;
      default:
        break;
    }
  });

  redirect(event, url, jsBlank, alternativeUrl, mobileUrl);
  return true;
}

/**
 * Assigning a listener to a click event and calling function
 */
export default function () {
  $('body').on(
    'click',
    `[${ALTERNATIVE_URL_SELECTOR}], [${MOBILE_URL_SELECTOR}], [${JS_BLANK_SELECTOR}]`,
    function eventHandler(event) {
      clickEventListener(event, this.dataset, this.href);
    });
}
