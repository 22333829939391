import { onRAF } from 'components/vendor/perform/utils';
import globalPubsub from 'pubsub.js';

import 'components/dropdown/style.scss';

const defaultSettings = {
  needWrapping: true,
  active: true,
};

/**
 * Dropdown component
 *
 * @fires Dropdown#dropdown/change - Emited after dropdown change
 *
 * @listens dropdown/resize - Resize dropdown to match max item width
 * @listens dropdown/init - Init dropdown
 * @listens dropdown/set-value - Set dropdown value
 *
 * @param {HtmlElement} context - Component context
 * @param {Object} settings - Component settings
 */
export default function (context, settings = {}) {
  const blockName = 'component-dropdown';
  const className = `.${blockName}`;
  const $context = $(context);
  const disabledBlock = `${blockName}--disabled`;
  const activeItemClassName = `${blockName}__custom-item--active`;
  const activeOptionsClassName = `${blockName}__custom-items--active`;
  const disabledOptionsClassName = `${blockName}__custom-item--disabled`;
  const activeSelectClassName = `${blockName}__custom--active`;
  const eventNamespace = 'dropdown';
  const pubsub = $(context).data('pubsub') || globalPubsub;
  const isDesktop = $('html').hasClass('dev-desktop');
  const $window = $(window);
  let $nativeSelect;
  let $dropdown;
  let $customSelect;
  let $customSelectLabel;
  let $customSelectOptions;

  if ($context.is('[data-dropdown-component-initialized]')) {
    return;
  }

  settings = $.extend({}, defaultSettings, settings);
  const needWrapping = !!settings.needWrapping;

  if (needWrapping) {
    $nativeSelect = $(context).addClass(`${blockName}__native`);
    $dropdown = $('<div>').addClass(blockName);
    $customSelect = $('<div>').addClass(`${blockName}__custom`);
    $customSelectLabel = $('<span>').addClass(`${blockName}__custom-label`);
    $dropdown.insertAfter($nativeSelect);
    $nativeSelect.appendTo($dropdown);
    $customSelect.appendTo($dropdown);
    $customSelectLabel.appendTo($customSelect);
    $('<span>').addClass(`${blockName}__custom-button`).appendTo($customSelect);
  } else {
    $dropdown = $(context);
    $nativeSelect = $dropdown.find(`${className}__native`);
    $customSelect = $dropdown.find(`${className}__custom`);
    $customSelectLabel = $dropdown.find(`${className}__custom-label`);
    $customSelectOptions = $dropdown.find(`${className}__custom-items`);
  }

  /**
   * Build custom options
   */
  function buildOptions() {
    $customSelectOptions.html('');

    $nativeSelect.find('option').each(function buildOption() {
      const $item = $('<li>').addClass(`${blockName}__custom-item`);
      $item
        .attr('data-value', this.value)
        .text(this.text)
        .appendTo($customSelectOptions)
      ;

      if (this.selected) {
        $item.addClass(activeOptionsClassName);
      }

      if (this.disabled) {
        $item.addClass(disabledOptionsClassName);
      }
    });
  }

  /**
   * Init module
   */
  function init() {
    if (isDesktop) {
      if (needWrapping) {
        $customSelectOptions = $('<ul>').addClass(`${blockName}__custom-items`);
        $customSelectOptions.appendTo($dropdown);
        buildOptions();
      }

      $dropdown.on('click', `${className}__custom`, (event) => {
        if (!settings.active) {
          return;
        }

        event.stopPropagation();
        toggleCustomOptions();
        pubsub.publish(`${eventNamespace}/toggle`, [context]);
      });

      $dropdown.on('click', `${className}__custom-item:not(.${disabledOptionsClassName})`,
        function selectOption() {
          if (!settings.active) {
            return;
          }

          const newValue = $(this).data('value');
          const value = getSelectedOption().value;

          if (value !== newValue) {
            $nativeSelect.val(newValue).change();
          }

          hideCustomOptions();
        }
      );

      findMaxWidth();

      $(window)
        .on('mouseup', hideCustomOptions)
        .on('resize load', findMaxWidth)
        .on('load', () => {
          onRAF(() => {
            $nativeSelect.trigger('updatelabel');
          });
        })
      ;
    }

    $nativeSelect.on('change updatelabel', (evt) => {
      onNativeChange(getSelectedOption());

      if (evt.type === 'change') {
        pubsub.publish(`${eventNamespace}/change`, [context, $nativeSelect.val()]);
      }
    });

    pubsub.subscribe(
      [
        `${eventNamespace}/resize`,
        `${eventNamespace}/init`,
      ],
      (eventContext) => {
        if (eventContext !== context) {
          return;
        }

        findMaxWidth();
      }
    );

    pubsub.subscribe(`${eventNamespace}/set-value`, (eventContext, newValue) => {
      if (eventContext !== context) {
        return;
      }

      setValue(newValue);
    });

    pubsub.subscribe(
      `${eventNamespace}/update-items`,
      (eventContext, items, selected, fields = { label: 'name', value: 'id' }) => {
        if (eventContext !== context) {
          return;
        }

        $nativeSelect.find('option').remove();

        items.forEach((item) => {
          const $option = $('<option>')
            .attr('value', item[fields.value])
            .text(item[fields.label]);
          $nativeSelect.append($option);
        });

        if (isDesktop) {
          buildOptions();
          findMaxWidth();
        }

        setValue(selected);
      }
    );

    pubsub.subscribe(`${eventNamespace}/toggleActive`, (eventContext, active) => {
      if (eventContext !== context || settings.active === active) {
        return;
      }

      settings.active = active;
      $context.toggleClass(disabledBlock, !active);
      $nativeSelect.prop('disabled', !active);
    });

    const selectedOption = getSelectedOption(true);
    onNativeChange(selectedOption);

    if (selectedOption) {
      $nativeSelect.val(selectedOption.value);
    }

    $context.attr('data-dropdown-component-initialized', '');

    $window.bind('pageshow', event => {
      if (event.originalEvent.persisted) {
        window.location.reload();
      }
    });
  }

  /**
   * Set value on select
   * @param {string} newValue - new value to set
   */
  function setValue(newValue) {
    const $newOption = $nativeSelect.find(`option[value="${newValue}"]`);

    if (!$newOption.length) {
      return;
    }

    $nativeSelect.val(newValue);
    onNativeChange($newOption.get(0));
  }

  /**
   * Find max width for dropdown
   */
  function findMaxWidth() {
    let maxWidth = 0;

    if (!$dropdown.is(':visible')) {
      return;
    }

    $dropdown.css('width', '');

    $nativeSelect
      .find('option')
      .each(function getWidth() {
        onNativeChange(this);
        maxWidth = Math.max(maxWidth, $dropdown.width());
      })
    ;

    onNativeChange(getSelectedOption());
    $dropdown.width(maxWidth + 2);
  }

  /**
   * Update custom select on native select change
   * @param {HtmlElement} option - clicked option
   */
  function onNativeChange(option) {
    if (!option) {
      option = $nativeSelect.find('option').get(0);
    }

    if (!option) {
      return;
    }

    $customSelectLabel.html(option.text);

    if (isDesktop) {
      $customSelect.data('value', option.value);
      $customSelectOptions
        .find(`.${activeItemClassName}`)
        .removeClass(activeItemClassName);

      $customSelectOptions
        .find(`li[data-value="${option.value}"]`)
        .addClass(activeItemClassName);
    }
  }

  /**
   * Toggle custom options visibility
   */
  function toggleCustomOptions() {
    onRAF(() => {
      $customSelect.toggleClass(activeSelectClassName);
      $customSelectOptions.toggleClass(activeOptionsClassName);

      if ($customSelect.data('scrolled') === undefined) {
        scrollToActiveCustomOption();
      }
    });
  }

  /**
   * Hide custom options
   * @param {EventObject} [event] - mouse event
   */
  function hideCustomOptions(event) {
    if ((event !== undefined) &&
      (event.target.className === $dropdown.attr('class') ||
        $dropdown.has(event.target).length)) {
      return;
    }

    onRAF(() => {
      $customSelect.removeClass(activeSelectClassName);
      $customSelectOptions.removeClass(activeOptionsClassName);
    });
  }

  /**
   * Scroll custom options to active element if not visible
   */
  function scrollToActiveCustomOption() {
    const $activeCustomOption = $customSelectOptions.find(`.${activeItemClassName}`);

    if ($activeCustomOption.length) {
      const elementTopPosition = $activeCustomOption.position().top;

      if (elementTopPosition > $customSelectOptions.scrollTop()) {
        $customSelect.data('scrolled', true);
        $customSelectOptions.scrollTop(elementTopPosition);
      }
    }
  }

  /**
   * Get native selector selected option
   *
   * @param {bool} [useSelected=false] - force to use option with selected attribute
   * @returns {HtmlElement} - selected option
   */
  function getSelectedOption(useSelected = false) {
    const bySelected = $nativeSelect.find('option[selected]').get(0);
    const byValue = $nativeSelect.find(`option[value="${$nativeSelect.val()}"]`).get(0);
    const byFirstSelectOption = $nativeSelect.find('option').get(0);

    if (useSelected) {
      return bySelected || byValue || byFirstSelectOption;
    }

    return byValue || bySelected || byFirstSelectOption;
  }

  init();
}
