import { delegateEvent, closest } from 'components/utils';
import pubsub from 'pubsub.js';

require('widgets/navigation/primary/style.scss');

/**
 * Navigation widget
 * @param {HTMLElement} context - widget context
 */
export default function (context) {
  const widgetClass = 'widget-navigation-primary';
  const itemClass = `${widgetClass}__item`;
  const submenuItemClass = `${itemClass}--sub-nav`;
  const itemsWithSubNav = [...context.querySelectorAll(`.${submenuItemClass}`)];

  /**
   * Close submenus
   * @param {HTMLElement} [exclude=null] - item to exclude
   */
  function closeSubmenus(exclude = null) {
    itemsWithSubNav.forEach((itemWithSubNav) => {
      if (itemWithSubNav !== exclude) {
        itemWithSubNav.classList.remove(`${itemClass}--opened`);
      }
    });
  }

  document.body.addEventListener('click', (event) => {
    if (!closest(event.target, `.${submenuItemClass}`, context)) {
      closeSubmenus();
    }
  }, false);

  pubsub.subscribe('header/hide', () => {
    closeSubmenus();
  });

  delegateEvent(context, 'click', `.${submenuItemClass}`, (event) => {
    const item = closest(event.target, `.${itemClass}`);

    if (!item.classList.contains(submenuItemClass)) {
      return;
    }

    event.preventDefault();
    item.classList.toggle(`${itemClass}--opened`);

    closeSubmenus(item);
  });
}
